(window.webpackJsonp=window.webpackJsonp||[]).push([[225],{"1Xr1":function(o,t,i){"use strict";i.d(t,"a",(function(){return r}));const r=i("Rukz").c`
  label {
    color: #475c7b;
    font-size: 14px !important;
    margin: 0px;
    font-weight: 700;
  }

  .value {
    font-size: 14px !important;
    color: #475c7b;
    margin: 0px;
  }

  .padded {
    padding: 5px;
  }
`},6988:function(o,t,i){"use strict";i.d(t,"a",(function(){return r}));const r=i("Rukz").c`
  #slider {
    position: relative;
    overflow: hidden;
    margin: 20px auto 0 auto;
    border-radius: 4px;
  }

  #slider ul {
    position: relative;
    margin: 0;
    padding: 0;
    height: 200px;
    list-style: none;
  }

  #slider ul li {
    position: relative;
    display: block;
    float: left;
    margin: 0;
    padding: 0;
    width: 500px;
    max-height: 300px;
    background: #ccc;
    text-align: center;
    line-height: 300px;
  }

  a.control_prev,
  a.control_next {
    position: absolute;
    top: 40%;
    z-index: 999;
    display: block;
    padding: 4% 3%;
    width: auto;
    height: auto;
    background: #2a2a2a;
    color: #fff;
    text-decoration: none;
    font-weight: 600;
    font-size: 18px;
    opacity: 0.8;
    cursor: pointer;
  }

  a.control_prev {
    border-radius: 0 2px 2px 0;
  }

  a.control_next {
    right: 0;
    border-radius: 2px 0 0 2px;
  }

  a.control_prev:hover,
  a.control_next:hover {
    opacity: 1;
    -webkit-transition: all 0.2s ease;
  }
  .slider_option {
    position: relative;
    margin: 10px auto;
    width: 160px;
    font-size: 18px;
  }
`},AZGv:function(o,t,i){"use strict";i.d(t,"a",(function(){return r}));const r=i("Rukz").c`
  six-button {
    padding: 0px;
    margin: 0px;
  }

  iron-overlay-backdrop:host(.opened) {
    opacity: var(--iron-overlay-backdrop-opacity, 0.6);
    pointer-events: auto;
    background-color: var(--iron-overlay-backdrop-background-color, #222) !important;
  }

  .sizeSm {
    width: 150px;
  }
  .sizeMd {
    width: 350px;
  }
  .sizeLg {
    width: 700px;
  }
`},BQIY:function(o,t,i){"use strict";i.d(t,"a",(function(){return r}));const r=i("Rukz").c`
  .timeline {
    list-style: none;
    padding: 0px 0px;
    position: relative;
  }

  .timeline:before {
    top: 10px;
    bottom: 0;
    position: absolute;
    content: ' ';
    width: 1px;
    background-color: #989898;
    left: 20px;
    margin-left: -1.5px;
  }

  .hidden {
    display: none;
  }

  .timeline .timeline-inverted {
    margin-bottom: 10px;
    position: relative;
  }

  .timeline > .timeline-inverted:before,
  .timeline > .timeline-inverted:after {
    content: ' ';
    display: table;
  }

  .timeline > .timeline-inverted:after {
    clear: both;
  }

  .timeline .timeline-panel {
    width: calc(100% - 70px);
    float: left !important;
    left: 64px;
    border: 1px solid #d4d4d4;
    border-radius: 4px;
    padding: 15px;
    position: relative;
    background-color: #fff;
    font-size: 14px;
    line-height: 23px;
    border-bottom: 5px solid #ddd;
  }

  .timeline .timeline-panel:before {
    position: absolute;
    top: 16px;
    right: -15px;
    display: inline-block;
    border-top: 15px solid transparent;
    border-left: 15px solid #ccc;
    border-right: 0 solid #ccc;
    border-bottom: 15px solid transparent;
    content: ' ';
  }

  .timeline .timeline-panel:after {
    position: absolute;
    top: 17px;
    right: -14px;
    display: inline-block;
    border-top: 14px solid transparent;
    border-left: 14px solid #fff;
    border-right: 0 solid #fff;
    border-bottom: 14px solid transparent;
    content: ' ';
  }

  .timeline .timeline-badge {
    color: #999;
    width: 40px;
    height: 40px;
    line-height: 38px;
    font-size: 1.2em;
    text-align: center;
    position: absolute;
    top: 6px;
    left: 25px;
    margin-left: -25px;
    background-color: #efefef;
    z-index: 100;
    border-top-right-radius: 50%;
    border-top-left-radius: 50%;
    border-bottom-right-radius: 50%;
    border-bottom-left-radius: 50%;
  }

  .timeline > .timeline-inverted > .timeline-panel:before {
    border-left-width: 0;
    border-right-width: 15px;
    left: -15px;
    right: auto;
  }

  .timeline > .timeline-inverted > .timeline-panel:after {
    border-left-width: 0;
    border-right-width: 14px;
    left: -14px;
    right: auto;
  }

  .timeline-heading p {
    margin-top: 0px;
    margin-bottom: 0px !important;
    font-size: 12px !important;
  }

  .timeline-title {
    margin: 0px !important;
    margin-top: 0px;
    color: var(--app-primary-color);
  }

  .timeline-body p {
    margin: 0px !important;
    margin-bottom: 0;
    line-height: 21px;
    font-size: 14px;
  }

  .timeline-body > p + p {
    margin-top: 0px;
  }

  .btn {
    border-radius: 3px;
    background: #475c7b;
    color: #fff;
    padding: 3px 10px;
    border: none;
    font-weight: bold;
    margin: 10px 0;
    cursor: pointer;
  }
`},CH88:function(o,t,i){"use strict";i.d(t,"a",(function(){return r}));const r=i("Rukz").c`
  svg {
    width: 100%;
    min-height: 40px;
  }
`},CQ1L:function(o,t,i){"use strict";i.d(t,"a",(function(){return r}));const r=i("Rukz").c`
  h3 {
    color: var(--app-primary-color);
    margin: 0px;
    font-size: 15px;
    font-weight: 500;
    height: 26px;
    line-height: 26px;
  }

  h3.no-line-height {
    color: var(--app-primary-color);
    margin: 0px;
    font-size: 15px;
    font-weight: 500;
    height: auto;
    line-height: auto;
  }

  h3.bold {
    color: var(--app-primary-color);
    margin: 0px;
    font-size: 15px;
    font-weight: 800;
  }

  h3.truncate {
    width: 95%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  h3.small {
    color: var(--app-primary-color);
    margin: 0px;
    font-size: 12px;
    font-weight: 500;
  }

  .m-t-0 {
    margin-top: 0px;
  }

  .m-t-5 {
    margin-top: 5px;
  }

  .m-t-10 {
    margin-top: 10px;
  }

  .m-b-5 {
    margin-bottom: 5px !important;
  }

  .m-b-10 {
    margin-bottom: 10px;
  }

  i {
    color: rgba(67, 82, 99, 0.8);
  }

  fontawesome-icon {
    float: left;
    margin-right: 7px;
  }
`},EtkK:function(o,t,i){"use strict";i.d(t,"a",(function(){return r}));const r=i("Rukz").c`
  .row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
  }

  .vaadin-label-strong {
    align-content: normal;
    align-items: normal;
    align-self: flex-start;
    box-sizing: border-box;
    color: rgb(135, 135, 135);
    cursor: default;
    display: block;
    flex-basis: auto;
    flex-direction: row;
    flex-wrap: nowrap;
    font-family: -apple-system, BlinkMacSystemFont, Roboto, 'Segoe UI', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
    font-size: 12px;
    font-weight: 700;
    height: 22px;
    justify-content: normal;
    line-height: 22px;
    margin-left: 0.75px;
    max-width: 100%;
    overflow-x: hidden;
    overflow-y: hidden;
    padding-bottom: 6px;
    padding-right: 12px;
    position: relative;
    text-overflow: ellipsis;
    white-space: nowrap;
    -webkit-font-smoothing: antialiased;
  }

  .container-field {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .container-field > * {
    align-self: flex-end;
  }

  .hr-hidden {
    visibility: hidden;
  }
`},FtLY:function(o,t,i){"use strict";i.d(t,"a",(function(){return r}));const r=i("Rukz").c`
  six-button {
    padding: 0px;
    margin: 0px;
  }

  iron-overlay-backdrop:host(.opened) {
    opacity: var(--iron-overlay-backdrop-opacity, 0.6);
    pointer-events: auto;
    background-color: var(--iron-overlay-backdrop-background-color, #222) !important;
  }

  textarea {
    background-color: #fff;
  }
`},GcTL:function(o,t,i){"use strict";i.d(t,"a",(function(){return r}));const r=i("Rukz").c`
  iron-overlay-backdrop:host(.opened) {
    opacity: var(--iron-overlay-backdrop-opacity, 0.1);
    pointer-events: auto;
    background-color: var(--iron-overlay-backdrop-background-color, #222) !important;
  }

  paper-dialog {
    max-width: 80vw;
  }

  .sizeSm {
    width: 150px;
  }
  .sizeMd {
    width: 550px;
  }
  .sizeLg {
    width: 1000px;
  }
`},HPki:function(o,t,i){"use strict";i.d(t,"a",(function(){return r}));const r=i("Rukz").c`
  .materiale {
    list-style: none;
    background-color: #fff;
    margin-bottom: 0px;
    border-radius: 0px;
    height: 54px;
    color: var(--app-primary-color);
    -webkit-transition: color 300ms, background-color 300ms, box-shadow 700ms;
    -moz-transition: color 300ms, background-color 300ms, box-shadow 700ms;
    -o-transition: color 300ms, background-color 300ms, box-shadow 700ms;
    transition: color 300ms, background-color 300ms, box-shadow 700ms;
    position: relative;
    border-bottom: 1px solid rgba(67, 82, 99, 0.3);
  }

  .materiale:hover {
    background-color: rgba(67, 82, 99, 0.1);
    transition: transform 0.4s ease-out, -webkit-transform 0.4s ease-out;
  }

  .materiale .icon {
    background: rgba(210, 221, 236, 0.6);
    border-bottom: 0px solid #ccc;
    cursor: pointer;
    height: 54px;
    line-height: 54px;
    width: 54px;
    border-radius: 0px;
    text-align: center;
    color: var(--app-primary-color);
    position: absolute;
    left: 0px;
  }

  .materiale.active {
    background-color: rgba(67, 82, 99, 0.1);
    list-style: none;
    margin-bottom: 0px;
    border-radius: 0px;
    height: 54px;
  }

  .materiale.active .icon {
    background: rgb(210, 221, 236);
    list-style: none;
    cursor: pointer;
    margin-bottom: 10px;
    border-radius: 0px;
    float: left;
    height: 54px;
  }

  .materiale .content {
    padding: 7px 20px;
    color: var(--app-primary-color);
    cursor: pointer;
    position: absolute;
    left: 54px;
    right: 54px;
    width: calc(100% - 100px);
  }

  .materiale .content .title {
    padding: 0px;
    width: calc(100% - 40px);
  }

  .materiale .content .title h3 {
    margin: 0px;
    padding: 0px;
    font-size: 15px;
  }

  .materiale .content .description {
    margin: 0px;
    padding: 0px;
    font-size: 12px;
    float: left;
    width: calc(100% - 40px);
  }
  .materiale .content .description p {
    float: left;
    margin: 0px;
    padding: 0px;
    margin-left: 0px;
  }

  .materiale .button {
    position: absolute;
    right: 0px;
    top: 0px;
    height: 54px;
    text-align: center;
    line-height: 70px;
    width: 54px;
  }
`},HvS7:function(o,t,i){"use strict";i("Rukz").c`
  .card-small {
    background-color: #fff;
    list-style: none;
    margin-bottom: 30px;
    border-radius: 4px;
    height: 100px;
    color: var(--app-primary-color);
    -webkit-transition: color 300ms, background-color 300ms, box-shadow 700ms;
    -moz-transition: color 300ms, background-color 300ms, box-shadow 700ms;
    -o-transition: color 300ms, background-color 300ms, box-shadow 700ms;
    transition: color 300ms, background-color 300ms, box-shadow 700ms;
  }

  .card-small:hover {
    background-color: #fff;
    box-shadow: 0 5px 20px 0 rgba(62, 57, 107, 0.07), 0 2px 9px 0 rgba(62, 57, 107, 0.06);
    transition: transform 0.4s ease-out, -webkit-transform 0.4s ease-out;
  }

  .card-small .icon {
    background-color: #44c455;
    border-bottom: 0px solid #ccc;
    height: 100px;
    border-right: 6px solid #3ca64a;
    width: 100px;
    border-radius: 4px 0px 0px 4px;
    text-align: center;
    color: #fff;
    float: left;
    position: relative;
  }

  .card-small h3.title {
    margin: 0px;
    padding: 0px;
  }

  .card-small.loading .icon {
    background-color: #ffbf2c;
    border-right: 6px solid #f5aa00;
  }
  .card-small.busy .icon {
    background-color: #f06061;
    border-right: 6px solid #e64249;
  }

  .card-small .icon h3 {
    margin: 0px;
    padding: 0px;
    font-size: 16px;
  }

  .card-small.active {
    border: 0px solid #333;
    outline: none;
    border-color: rgba(71, 92, 123, 0.5);
    box-shadow: 0 0 10px rgba(71, 92, 123, 0.5);
  }

  .card-small .icon iron-icon {
    height: 40px;
    width: 40px;
    margin-top: 14px;
    margin-bottom: 6px;
  }

  .card-small.active .icon {
    background-color: rgb(71, 92, 123);
    border-right: 6px solid rgba(71, 92, 123, 0.7);
    height: 100px;
    width: 100px;
  }

  .card-small .content {
    color: var(--app-primary-color);
    width: calc(100% - 100px);
    margin-left: 100px;
  }

  .card-small .content .inner {
    padding: 20px;
  }

  .card-small .content h3.title {
    margin: 0px;
    padding: 0px;
    font-size: 20px;
  }

  .card-small .content p.description {
    margin: 0px;
    padding: 0px;
    font-size: 12px;
  }

  .icon-checked {
    background-color: rgb(68, 196, 85);
    width: 40px;
    height: 40px;
    position: absolute;
    z-index: 100;
    left: 0px;
    top: 0px;
    border-radius: 3px 0px 0px 0px;
    line-height: 42px;
    display: none;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 6px;
  }

  .icon-checked.active {
    display: block;
  }

  .icon-checked.icon {
    line-height: 30px;
  }

  .progress-wrapper {
    margin-bottom: 5px;
    margin-top: 2px;
  }
`},Hzap:function(o,t,i){"use strict";i.d(t,"a",(function(){return r}));const r=i("Rukz").c`
  iron-icon {
    margin-right: 20px;
  }

  .elements-item {
    background-color: #fff;
    border-bottom: 1px solid #ccc;
    list-style: none;
    color: var(--app-primary-color);
    -webkit-transition: color 300ms, background-color 300ms, box-shadow 700ms;
    -moz-transition: color 300ms, background-color 300ms, box-shadow 700ms;
    -o-transition: color 300ms, background-color 300ms, box-shadow 700ms;
    transition: color 300ms, background-color 300ms, box-shadow 700ms;
    padding: 8px 12px;
    margin: 0px;
  }

  .elements-item h3 {
    font-size: 15px;
    margin: 0px 0px;
  }

  .elements-item p {
    font-size: 15px;
    margin: 0px 0px;
  }

  .elements-item:hover {
    background-color: rgba(67, 82, 99, 0.1);
    transition: transform 0.4s ease-out, -webkit-transform 0.4s ease-out;
  }

  .item-content {
    line-height: 19px;
  }
  .qty {
    float: left;
    text-align: left;
  }

  .qty .count {
    color: #000;
    display: inline-block;
    vertical-align: top;
    font-size: 14px;
    line-height: 30px;
    height: 30px;
    padding: 0 3px;
    min-width: 40px;
    text-align: center;
    border: 1px solid #ccc;
    border-radius: 3px;
  }

  .qty .plus {
    cursor: pointer;
    display: inline-block;
    vertical-align: top;
    color: white;
    width: 26px;
    height: 26px;
    text-align: center;
    border-radius: 3px;
    background-color: var(--app-success-color);
  }

  .qty .minus {
    cursor: pointer;
    display: inline-block;
    vertical-align: top;
    color: white;
    width: 26px;
    height: 26px;
    text-align: center;
    border-radius: 3px;
    background-clip: padding-box;
    background-color: var(--app-primary-color);
  }

  .minus:hover {
    background-color: #999999 !important;
  }

  .plus:hover {
    background-color: #999999 !important;
  }

  /*Prevent text selection*/
  span {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
  }

  input {
    border: 0;
    width: 2%;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input:disabled {
    background-color: white;
  }

  .progress-wrapper {
    margin-bottom: 5px;
    margin-top: 2px;
  }

  .progress-wrapper .progress {
    display: -ms-flexbox;
    display: flex;
    height: 10px;
    overflow: hidden;
    font-size: 0.703125rem;
    background-color: #e9ecef;
    border-radius: 3px;
    padding-bottom: 0px;
    margin-bottom: 0px;
  }

  .progress-wrapper p {
    font-size: 12px;
    padding-bottom: 0px;
    margin: 0px;
    margin-bottom: 2px;
    color: var(--app-primary-color);
    font-weight: 600;
  }

  .progress-wrapper .progress-bar {
    color: #fff;
    text-align: center;
    transition: width 0.6s ease;
  }

  .progress-wrapper .bg-blue {
    background-color: var(--app-primary-color) !important;
  }

  .progress-wrapper .progress-bar-striped {
    background-image: linear-gradient(
      45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent
    );
    background-size: 1rem 1rem;
  }

  .progress-wrapper .progress-bar-animated {
    -webkit-animation: progress-bar-stripes 1s linear infinite;
    animation: progress-bar-stripes 1s linear infinite;
  }

  .truncate {
    width: 95%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .code {
    font-size: 13px !important;
  }
`},IQUN:function(o,t,i){"use strict";i.d(t,"a",(function(){return r}));const r=i("Rukz").c`
  iron-icon {
    margin-right: 20px;
  }

  .elements-item {
    background-color: #fff;
    border-bottom: 1px solid #ccc;
    list-style: none;
    color: var(--app-primary-color);
    -webkit-transition: color 300ms, background-color 300ms, box-shadow 700ms;
    -moz-transition: color 300ms, background-color 300ms, box-shadow 700ms;
    -o-transition: color 300ms, background-color 300ms, box-shadow 700ms;
    transition: color 300ms, background-color 300ms, box-shadow 700ms;
    padding: 6px 8px 3px 8px;
    margin: 0px;
  }

  .elements-item h3 {
    font-size: 15px;
    margin: 0px 0px;
  }

  .elements-item p {
    font-size: 15px;
    margin: 0px 0px;
  }

  .elements-item:hover {
    background-color: rgba(67, 82, 99, 0.1);
    transition: transform 0.4s ease-out, -webkit-transform 0.4s ease-out;
  }

  .qty {
    float: left;
    text-align: left;
  }
  .qty .count {
    color: #000;
    display: inline-block;
    vertical-align: top;
    font-size: 14px;
    line-height: 30px;
    height: 30px;
    padding: 0 3px;
    min-width: 40px;
    text-align: center;
    border: 1px solid #ccc;
    border-radius: 3px;
  }
  .qty .plus {
    cursor: pointer;
    display: inline-block;
    vertical-align: top;
    color: white;
    width: 26px;
    height: 26px;
    text-align: center;
    border-radius: 3px;
    background-color: var(--app-success-color);
  }
  .qty .minus {
    cursor: pointer;
    display: inline-block;
    vertical-align: top;
    color: white;
    width: 26px;
    height: 26px;
    text-align: center;
    border-radius: 3px;
    background-clip: padding-box;
    background-color: var(--app-primary-color);
  }

  .minus:hover {
    background-color: #999999 !important;
  }

  .plus:hover {
    background-color: #999999 !important;
  }
  /*Prevent text selection*/
  span {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
  }

  input {
    border: 0;
    width: 2%;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input:disabled {
    background-color: white;
  }

  .progress-wrapper {
    margin-bottom: 5px;
    margin-top: 2px;
  }
  .progress-wrapper .progress {
    display: -ms-flexbox;
    display: flex;
    height: 10px;
    overflow: hidden;
    font-size: 0.703125rem;
    background-color: #e9ecef;
    border-radius: 3px;
    padding-bottom: 0px;
    margin-bottom: 0px;
  }
  .progress-wrapper p {
    font-size: 12px;
    padding-bottom: 0px;
    margin: 0px;
    margin-bottom: 2px;
    color: var(--app-primary-color);
    font-weight: 600;
  }
  .progress-wrapper .progress-bar {
    color: #fff;
    text-align: center;
    transition: width 0.6s ease;
  }
  .progress-wrapper .bg-blue {
    background-color: var(--app-primary-color) !important;
  }
  .progress-wrapper .progress-bar-striped {
    background-image: linear-gradient(
      45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent
    );
    background-size: 1rem 1rem;
  }
  .progress-wrapper .progress-bar-animated {
    -webkit-animation: progress-bar-stripes 1s linear infinite;
    animation: progress-bar-stripes 1s linear infinite;
  }

  .truncate {
    width: 95%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  six-badge {
    cursor: pointer;
  }
`},Ivn2:function(o,t,i){"use strict";var r=i("Rukz");i("h/KH");r.c`
  paper-fab {
    --paper-fab-background: var(--app-primary-color);
    position: fixed;
    right: 25px;
    bottom: 30px;
  }

  :host {
    color: var(--lumo-body-text-color);
    overflow-y: auto;
    padding: 20px;
  }

  paper-button {
    padding: 8px 16px;
    border-radius: 3px;
    font-size: 15px;
  }

  paper-button.blue {
    background-color: var(--paper-blue-500);
    color: white;

    --paper-button-raised-keyboard-focus: {
      background-color: var(--paper-blue-a200) !important;
      color: white !important;
    }
  }

  paper-button.blue:hover {
    background-color: var(--paper-blue-400);
  }

  .panel {
    padding: 0px 20px 20px 20px;
    background-color: #fff;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
  }

  .center {
    width: 300px;
    display: block;
    padding-bottom: var(--lumo-space-m);
    margin: var(--lumo-space-m) auto auto;
  }

  .center vaadin-text-field {
    width: 100%;
  }

  vaadin-button {
    margin-left: auto;
    margin-right: auto;
  }

  .centered {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .l-wrap {
    margin-right: auto;
    margin-left: auto;
  }

  .grid-item {
    width: 100%;
    margin-top: 24px;
    margin-right: 0px;
    float: left;
    justify-content: center;
    align-items: center;
    height: 160px;
    text-align: center;
  }

  /* For a 3-column grid */
  .grid-item:nth-child(3n + 3) {
    margin-right: 0;
    float: right;
  }

  .btn-logout {
    border-radius: 4px;
    width: 100%;
    height: 100%;
    color: var(--app-primary-color);
    cursor: pointer;
    background: -webkit-linear-gradient(top, #ffffff 0%, #f6f6f6 100%);
    font-size: 13px;
    border: 1px solid #ddd;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    -webkit-transition: color 300ms, background-color 300ms;
    -moz-transition: color 300ms, background-color 300ms;
    -o-transition: color 300ms, background-color 300ms;
    transition: color 300ms, background-color 300ms;
  }

  .btn-logout:hover {
    border-radius: 0.4em;
    width: 100%;
    height: 100%;
    color: #fff;
    cursor: pointer;
    background: var(--app-primary-color);
  }

  .btn-logout .button-label {
    font-size: 15px;
  }

  .btn-logout iron-icon {
    width: 5em;
    height: 5em;
    color: var(--app-selected-color);
    margin: 0.5em;
    margin-top: 30px;
  }

  fontawesome-icon {
    width: 5em;
    height: 5em;
    color: #4fc1e9;
    /* margin: 15px 0.5em 0.5em; */
    font-size: 45px;
  }

  [class^='icon-'],
  [class*=' icon-'] {
    height: 32px;
    width: 32px;
    display: inline-block;
    fill: currentColor;
  }

  .notification {
    color: var(--lumo-body-text-color);
  }
`},KLPv:function(o,t,i){"use strict";i("Rukz").c`
  fontawesome-icon {
    float: left;
  }
`},Ms4V:function(o,t,i){"use strict";i.d(t,"a",(function(){return r}));const r=i("Rukz").c`
  .lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 100%;
    height: 64px;
  }
  .center {
    position: absolute;
    left: 48%;
    transform: translate(-45%, -45%);
  }
  .lds-ellipsis div > div {
    position: absolute;
    top: 27px;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background: #fff;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
    background-color: #2a4365;
  }
  .lds-ellipsis div > div:nth-child(1) {
    left: 6px;
    animation: lds-ellipsis1 0.6s infinite;
  }
  .lds-ellipsis div > div:nth-child(2) {
    left: 6px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div > div:nth-child(3) {
    left: 26px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div > div:nth-child(4) {
    left: 45px;
    animation: lds-ellipsis3 0.6s infinite;
  }

  .lds-ellipsis.small div > div {
    position: absolute;
    top: 27px;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background: #fff;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
    background-color: #2a4365;
  }
  .lds-ellipsis.small div > div:nth-child(1) {
    left: 2px;
    animation: lds-ellipsis1 0.6s infinite;
  }
  .lds-ellipsis.small div > div:nth-child(2) {
    left: 2px;
    animation: lds-ellipsis2-small 0.6s infinite;
  }
  .lds-ellipsis.small div > div:nth-child(3) {
    left: 10px;
    animation: lds-ellipsis2-small 0.6s infinite;
  }
  .lds-ellipsis.small div > div:nth-child(4) {
    left: 18px;
    animation: lds-ellipsis3 0.6s infinite;
  }

  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(19px, 0);
    }
  }

  @keyframes lds-ellipsis2-small {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(8px, 0);
    }
  }

  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
`},Mvqi:function(o,t,i){"use strict";i.d(t,"a",(function(){return r}));const r=i("Rukz").c`
  .row {
    display: flex;
    align-items: baseline;
  }

  .row .delimiter {
    padding: 0 var(--lumo-space-s);
  }

  .title {
    justify-content: space-between;
    align-items: baseline;
  }

  :host() {
    width: 100%;
  }

  vaadin-combo-box {
    width: 100%;
  }

  input[theme~='custom'] {
    color: #333 !important;
  }

  vaadin-text-field {
    margin: 0px !important;
    padding: 0px !important;
  }

  .row vaadin-text-field {
    min-width: 0;
    flex: auto;
  }
`},NArn:function(o,t,i){"use strict";i.d(t,"a",(function(){return r}));const r=i("Rukz").c`
  :host {
    margin: 0px;
    padding: 0px !important;
    border: 0px solid #333;
    height: 30px;
  }

  .btn {
    padding: 0px 10px !important;
    border-radius: 6px;
    font-size: 15px;
    color: #fff;
    border: 0px solid #fff;
    -webkit-transition: all 0.1s;
    -moz-transition: all 0.1s;
    -ms-transition: all 0.1s;
    transition: all 0.1s;
    margin: 0px !important;
    cursor: pointer;
    font-weight: 600;
    height: 30px;
    line-height: 30px;
    background-color: transparent;
  }

  button {
    display: flex;
  }

  .btn.btn-lg {
    font-size: 14px;
    height: 34px;
    line-height: 34px;
  }
  .btn.icon-margin-right {
    height: 24px;
  }
  .btn.btn-md {
    font-size: 14px;
    height: 30px;
    line-height: 30px;
  }

  .btn.btn-md.only-icon {
    width: 30px;
    padding: 0px !important;
    text-align: center;
  }

  fontawesome-icon {
    float: left;
  }

  .btn.btn-md.only-icon fontawesome-icon {
    float: none;
    margin: 0 auto;
  }

  .btn.btn-sm {
    font-size: 13px;
    border-radius: 4px;
    height: 30px;
    line-height: 30px;
  }
  .btn.btn-xs {
    font-size: 11px;
    border-radius: 4px;
    height: 26px;

    line-height: 26px;
    padding: 0px 8px !important;
    margin-top: 3px !important;
    text-align: center !important;
  }

  .btn.btn-xs.only-icon {
    height: 26px;
    width: 26px;
    line-height: 26px;
  }

  .btn.btn-xs.only-icon fontawesome-icon {
    float: none;
    margin: 0 auto;
  }

  .btn.btn-transparent {
    color: var(--app-primary-color);
    background-color: transparent;
  }

  .btn.btn-success {
    background-color: var(--app-success-color);
  }

  .btn.btn-success.active {
    opacity: 0.3;
    pointer-events: auto !important;
    cursor: not-allowed !important;
  }
  .btn.btn-add-cart {
    background-color: var(--app-primary-color);
  }
  .btn.btn-add-cart.active {
    background-color: var(--app-success-color);

    opacity: 0.7;
    pointer-events: auto !important;
    cursor: not-allowed !important;
  }
  .btn.btn-default {
    background-color: var(--app-primary-color);
    color: #fff;
  }
  .btn.btn-default-outline {
    background-color: #fff;
    border: 1px solid var(--app-primary-color);
    color: var(--app-primary-color);
  }
  .btn.btn-default-outline:hover {
    background-color: var(--app-primary-color);
    border: 1px solid var(--app-primary-color);
    color: #ffffff;
  }

  .btn.btn-download-outline {
    background-color: #fff;
    border: 1px solid var(--app-danger-color);
    color: var(--app-danger-color);
  }
  .btn.btn-download-outline:hover {
    background-color: var(--app-danger-color);
    border: 1px solid var(--app-danger-color);
    color: #ffffff;
  }

  .btn.btn-block {
    display: block;
    width: 100%;
  }
  .btn.btn-success-outline {
    color: var(--app-success-color) !important;
    background-color: #fff;
    border: 1px solid var(--app-success-color) !important;
    margin-bottom: 0px;
    border-width: 1px;
  }
  .btn.btn-success-outline:hover {
    color: #fff !important;
    background-color: var(--app-success-color);
    border: 1px solid var(--app-success-color) !important;
    margin-bottom: 0px;
    border-width: 1px;
  }
  .btn.btn-success-outline i {
    color: var(--app-success-color);
  }
  .btn.btn-white-outline {
    color: #ffffff !important;
    background-color: transparent !important;
    border: 1px solid #ffffff !important;
    margin-bottom: 0px;
    border-width: 1px;
  }
  .btn.btn-white-outline i {
    color: #ffffff;
  }

  .btn.btn-warning {
    color: #fff;
    background-color: #ff9f00 !important;
  }
  .btn.btn-warning.outline {
    color: #ff9f00 !important;
    background-color: #fff !important;
    border-color: #ff9f00 !important;
    margin-bottom: 10px;
    border-width: 1px;
  }
  .btn.btn-print {
    color: #fff;
    background-color: #999999 !important;
  }
  .btn.btn-info {
    color: #fff;
    background-color: var(--app-selected-color);
    border: 1px solid var(--app-selected-color);
  }
  .btn.btn-info.outline {
    color: #777777;
    background-color: #fff;
    border-color: var(--app-selected-color);
    margin-bottom: 10px;
    border-width: 1px;
  }
  .btn-info.outline i {
    color: var(--app-selected-color);
  }
  .btn-danger {
    color: #fff;
    background-color: #da4453;
    border: 1px solid #da4453;
  }
  .btn-danger-outline {
    color: #da4453;
    background-color: #fff;
    border: 1px solid #da4453;
    margin-bottom: 10px;
  }
  .btn-block {
    margin: 0px;
    display: block;
    width: 100%;
  }
  .btn-primary {
    color: #2b4a60;
    background-color: #fff;
    padding: 0px 8px !important;
    border: 1px solid #e9ecef;
  }
  .btn-overflow-right {
    position: absolute;
    top: 5px;
    right: 5px;
  }
  .btn-overflow-left {
    position: absolute;
    top: 5px;
    left: 5px;
  }
  .btn-pagination {
    color: #777;
    background-color: transparent;
    border-color: #fff;
    margin: 0px 2px;
  }
  .btn-default.outline {
    color: #999;
    background-color: #ffffff;
    border-color: #999;
    margin-bottom: 10px;
    border-width: 1px;
  }
  .btn-default.outline:hover,
  .btn-warning.outline:hover,
  .btn-info.outline:hover,
  .btn-danger.outline:hover,
  .btn-success.outline:hover,
  .btn-default.outline:focus,
  .btn-warning.outline:focus,
  .btn-info.outline:focus,
  .btn-danger.outline:focus,
  .btn-success.outline:focus {
    background-color: #f5f5f5 !important;
    color: #666 !important;
  }
  .btn-check.btn-xs,
  .btn-add.btn-xs {
    height: 24px;
    margin: 0px !important;
    line-height: 24px;
    text-align: center;
    padding: 0px 6px !important;
  }

  .btn.btn-disabled,
  .btn:disabled,
  [disabled] {
    margin: 0px;
    color: #524F4F !important;
    background-color: #c0bfbf !important;
    border: 1px solid #ddd !important;
  }

  .btn.btn-disabled:hover,
  .btn:disabled:hover,
  [disabled]:hover {
    margin: 0px;
    color: #524F4F !important;
    background-color: #c0bfbf !important;
    border: 1px solid #ddd !important;
    pointer-events: auto !important;
    cursor: not-allowed !important;
  }

  .label-margin {
    margin-top: 25px;
  }

  .icon-margin-right {
    margin: 0px;
    margin-right: 7px;
  }

  .mt-5 {
    margin-top: 5px !important;
  }
  .mt-10 {
    margin-top: 10px !important;
  }
  .mt-15 {
    margin-top: 15px !important;
  }
  .mt-20 {
    margin-top: 20px !important;
  }
  .mt-22 {
    margin-top: 22px !important;
  }
  .mt-25 {
    margin-top: 25px !important;
  }
  .mr-5 {
    margin-right: 5px !important;
  }
  .mr-10 {
    margin-right: 10px !important;
  }

  .btn-info.active {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 6px;
    color: #2d3c51;
    border: 1px solid #2d3c51;
  }

  .btn-filter {
    border-radius: 50px !important;
    margin-bottom: 6px !important;
    margin-right: 5px !important;
    background-color: rgb(255, 255, 255) !important;
    border: 1px solid rgba(71, 92, 123, 0.1) !important;
    color: rgb(71, 92, 123) !important;
    font-size: 13px !important;
    outline: none !important;
    padding: 0px 10px !important;
  }

  .btn-filter.active {
    background-color: rgb(71, 92, 123) !important;
    color: rgb(255, 255, 255) !important;
    border: 1px solid rgb(71, 92, 123) !important;
  }

  .btn iron-icon {
    max-height: 20px;
    margin-top: -3px;
  }
`},NLqr:function(o,t,i){"use strict";i.d(t,"a",(function(){return r}));const r=i("Rukz").c`
  .hide {
    display: none;
  }
  .show {
    display: block;
  }
  vaadin-tabs {
    box-shadow: inset 0 0px 0 0 var(--lumo-contrast-10pct) !important;
    position: relative;
    min-height: var(--lumo-size-l);
  }

  :host([selected]) {
    color: var(--lumo-primary-text-color);
    transition: 0.6s color;
  }
`},OKNL:function(o,t,i){"use strict";i.d(t,"a",(function(){return r}));const r=i("Rukz").c`
  .icon {
    height: 60px;
    color: #475c7b;
    width: 60px;
    margin-top: 20px;
    font-size: 50px;
  }

  h3.truncate {
    margin: 0px;
    color: #475c7b;
  }

  .number {
    background-color: rgba(210, 221, 236, 0.5);
    border-radius: 4px;
    padding: 10px 30px;
    margin-top: 10px;
    color: #475c7b;
    font-weight: 600;
  }

  .selected {
    background-color: var(--app-selected-color) !important;
  }
`},PG1f:function(o,t,i){"use strict";i.d(t,"a",(function(){return r}));const r=i("Rukz").c`
  * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  *:before,
  *:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }

  /**
   * GRID
   */
  .container {
    margin-right: auto;
    margin-left: auto;
    padding-left: 15px;
    padding-right: 15px;
  }
  @media (min-width: 768px) {
    .container {
      width: 750px;
    }
  }
  @media (min-width: 992px) {
    .container {
      width: 970px;
    }
  }
  @media (min-width: 1200px) {
    .container {
      width: 1170px;
    }
  }
  .container-fluid {
    margin-right: auto;
    margin-left: auto;
    padding-left: 15px;
    padding-right: 15px;
  }
  .row {
    margin-left: -10px;
    margin-right: -10px;
  }

  .row:before,
  .row:after {
    display: table;
    content: ' ';
  }

  .row:after {
    clear: both;
  }

  .row-eq-height {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }

  .col-xs-1,
  .col-sm-1,
  .col-md-1,
  .col-lg-1,
  .col-xs-2,
  .col-sm-2,
  .col-md-2,
  .col-lg-2,
  .col-xs-3,
  .col-sm-3,
  .col-md-3,
  .col-lg-3,
  .col-xs-4,
  .col-sm-4,
  .col-md-4,
  .col-lg-4,
  .col-xs-5,
  .col-sm-5,
  .col-md-5,
  .col-lg-5,
  .col-xs-6,
  .col-sm-6,
  .col-md-6,
  .col-lg-6,
  .col-xs-7,
  .col-sm-7,
  .col-md-7,
  .col-lg-7,
  .col-xs-8,
  .col-sm-8,
  .col-md-8,
  .col-lg-8,
  .col-xs-9,
  .col-sm-9,
  .col-md-9,
  .col-lg-9,
  .col-xs-10,
  .col-sm-10,
  .col-md-10,
  .col-lg-10,
  .col-xs-11,
  .col-sm-11,
  .col-md-11,
  .col-lg-11,
  .col-xs-12,
  .col-sm-12,
  .col-md-12,
  .col-lg-12 {
    position: relative;
    min-height: 1px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .col-xs-1,
  .col-xs-2,
  .col-xs-3,
  .col-xs-4,
  .col-xs-5,
  .col-xs-6,
  .col-xs-7,
  .col-xs-8,
  .col-xs-9,
  .col-xs-10,
  .col-xs-11,
  .col-xs-12 {
    float: left;
  }

  .col-xs-15,
  .col-sm-15,
  .col-md-15,
  .col-lg-15 {
    position: relative;
    min-height: 1px;
    padding-right: 10px;
    padding-left: 10px;
  }
  .col-xs-15 {
    width: 20%;
    float: left;
  }
  @media (min-width: 768px) {
    .col-sm-15 {
      width: 20%;
      float: left;
    }
  }
  @media (min-width: 992px) {
    .col-md-15 {
      width: 20%;
      float: left;
    }
  }
  @media (min-width: 1200px) {
    .col-lg-15 {
      width: 20%;
      float: left;
    }
  }

  .col-xs-12 {
    width: 100%;
  }
  .col-xs-11 {
    width: 91.66666667%;
  }
  .col-xs-10 {
    width: 83.33333333%;
  }
  .col-xs-9 {
    width: 75%;
  }
  .col-xs-8 {
    width: 66.66666667%;
  }
  .col-xs-7 {
    width: 58.33333333%;
  }
  .col-xs-6 {
    width: 50%;
  }
  .col-xs-5 {
    width: 41.66666667%;
  }
  .col-xs-4 {
    width: 33.33333333%;
  }
  .col-xs-3 {
    width: 25%;
  }
  .col-xs-2 {
    width: 16.66666667%;
  }
  .col-xs-1 {
    width: 8.33333333%;
  }
  .col-xs-pull-12 {
    right: 100%;
  }
  .col-xs-pull-11 {
    right: 91.66666667%;
  }
  .col-xs-pull-10 {
    right: 83.33333333%;
  }
  .col-xs-pull-9 {
    right: 75%;
  }
  .col-xs-pull-8 {
    right: 66.66666667%;
  }
  .col-xs-pull-7 {
    right: 58.33333333%;
  }
  .col-xs-pull-6 {
    right: 50%;
  }
  .col-xs-pull-5 {
    right: 41.66666667%;
  }
  .col-xs-pull-4 {
    right: 33.33333333%;
  }
  .col-xs-pull-3 {
    right: 25%;
  }
  .col-xs-pull-2 {
    right: 16.66666667%;
  }
  .col-xs-pull-1 {
    right: 8.33333333%;
  }
  .col-xs-pull-0 {
    right: auto;
  }
  .col-xs-push-12 {
    left: 100%;
  }
  .col-xs-push-11 {
    left: 91.66666667%;
  }
  .col-xs-push-10 {
    left: 83.33333333%;
  }
  .col-xs-push-9 {
    left: 75%;
  }
  .col-xs-push-8 {
    left: 66.66666667%;
  }
  .col-xs-push-7 {
    left: 58.33333333%;
  }
  .col-xs-push-6 {
    left: 50%;
  }
  .col-xs-push-5 {
    left: 41.66666667%;
  }
  .col-xs-push-4 {
    left: 33.33333333%;
  }
  .col-xs-push-3 {
    left: 25%;
  }
  .col-xs-push-2 {
    left: 16.66666667%;
  }
  .col-xs-push-1 {
    left: 8.33333333%;
  }
  .col-xs-push-0 {
    left: auto;
  }
  .col-xs-offset-12 {
    margin-left: 100%;
  }
  .col-xs-offset-11 {
    margin-left: 91.66666667%;
  }
  .col-xs-offset-10 {
    margin-left: 83.33333333%;
  }
  .col-xs-offset-9 {
    margin-left: 75%;
  }
  .col-xs-offset-8 {
    margin-left: 66.66666667%;
  }
  .col-xs-offset-7 {
    margin-left: 58.33333333%;
  }
  .col-xs-offset-6 {
    margin-left: 50%;
  }
  .col-xs-offset-5 {
    margin-left: 41.66666667%;
  }
  .col-xs-offset-4 {
    margin-left: 33.33333333%;
  }
  .col-xs-offset-3 {
    margin-left: 25%;
  }
  .col-xs-offset-2 {
    margin-left: 16.66666667%;
  }
  .col-xs-offset-1 {
    margin-left: 8.33333333%;
  }
  .col-xs-offset-0 {
    margin-left: 0;
  }
  @media (min-width: 768px) {
    .col-sm-1,
    .col-sm-2,
    .col-sm-3,
    .col-sm-4,
    .col-sm-5,
    .col-sm-6,
    .col-sm-7,
    .col-sm-8,
    .col-sm-9,
    .col-sm-10,
    .col-sm-11,
    .col-sm-12 {
      float: left;
    }
    .col-sm-12 {
      width: 100%;
    }
    .col-sm-11 {
      width: 91.66666667%;
    }
    .col-sm-10 {
      width: 83.33333333%;
    }
    .col-sm-9 {
      width: 75%;
    }
    .col-sm-8 {
      width: 66.66666667%;
    }
    .col-sm-7 {
      width: 58.33333333%;
    }
    .col-sm-6 {
      width: 50%;
    }
    .col-sm-5 {
      width: 41.66666667%;
    }
    .col-sm-4 {
      width: 33.33333333%;
    }
    .col-sm-3 {
      width: 25%;
    }
    .col-sm-2 {
      width: 16.66666667%;
    }
    .col-sm-1 {
      width: 8.33333333%;
    }
    .col-sm-pull-12 {
      right: 100%;
    }
    .col-sm-pull-11 {
      right: 91.66666667%;
    }
    .col-sm-pull-10 {
      right: 83.33333333%;
    }
    .col-sm-pull-9 {
      right: 75%;
    }
    .col-sm-pull-8 {
      right: 66.66666667%;
    }
    .col-sm-pull-7 {
      right: 58.33333333%;
    }
    .col-sm-pull-6 {
      right: 50%;
    }
    .col-sm-pull-5 {
      right: 41.66666667%;
    }
    .col-sm-pull-4 {
      right: 33.33333333%;
    }
    .col-sm-pull-3 {
      right: 25%;
    }
    .col-sm-pull-2 {
      right: 16.66666667%;
    }
    .col-sm-pull-1 {
      right: 8.33333333%;
    }
    .col-sm-pull-0 {
      right: auto;
    }
    .col-sm-push-12 {
      left: 100%;
    }
    .col-sm-push-11 {
      left: 91.66666667%;
    }
    .col-sm-push-10 {
      left: 83.33333333%;
    }
    .col-sm-push-9 {
      left: 75%;
    }
    .col-sm-push-8 {
      left: 66.66666667%;
    }
    .col-sm-push-7 {
      left: 58.33333333%;
    }
    .col-sm-push-6 {
      left: 50%;
    }
    .col-sm-push-5 {
      left: 41.66666667%;
    }
    .col-sm-push-4 {
      left: 33.33333333%;
    }
    .col-sm-push-3 {
      left: 25%;
    }
    .col-sm-push-2 {
      left: 16.66666667%;
    }
    .col-sm-push-1 {
      left: 8.33333333%;
    }
    .col-sm-push-0 {
      left: auto;
    }
    .col-sm-offset-12 {
      margin-left: 100%;
    }
    .col-sm-offset-11 {
      margin-left: 91.66666667%;
    }
    .col-sm-offset-10 {
      margin-left: 83.33333333%;
    }
    .col-sm-offset-9 {
      margin-left: 75%;
    }
    .col-sm-offset-8 {
      margin-left: 66.66666667%;
    }
    .col-sm-offset-7 {
      margin-left: 58.33333333%;
    }
    .col-sm-offset-6 {
      margin-left: 50%;
    }
    .col-sm-offset-5 {
      margin-left: 41.66666667%;
    }
    .col-sm-offset-4 {
      margin-left: 33.33333333%;
    }
    .col-sm-offset-3 {
      margin-left: 25%;
    }
    .col-sm-offset-2 {
      margin-left: 16.66666667%;
    }
    .col-sm-offset-1 {
      margin-left: 8.33333333%;
    }
    .col-sm-offset-0 {
      margin-left: 0;
    }
  }
  @media (min-width: 992px) {
    .col-md-1,
    .col-md-2,
    .col-md-3,
    .col-md-4,
    .col-md-5,
    .col-md-6,
    .col-md-7,
    .col-md-8,
    .col-md-9,
    .col-md-10,
    .col-md-11,
    .col-md-12 {
      float: left;
    }
    .col-md-12 {
      width: 100%;
    }
    .col-md-11 {
      width: 91.66666667%;
    }
    .col-md-10 {
      width: 83.33333333%;
    }
    .col-md-9 {
      width: 75%;
    }
    .col-md-8 {
      width: 66.66666667%;
    }
    .col-md-7 {
      width: 58.33333333%;
    }
    .col-md-6 {
      width: 50%;
    }
    .col-md-5 {
      width: 41.66666667%;
    }
    .col-md-4 {
      width: 33.33333333%;
    }
    .col-md-3 {
      width: 25%;
    }
    .col-md-2 {
      width: 16.66666667%;
    }
    .col-md-1 {
      width: 8.33333333%;
    }
    .col-md-pull-12 {
      right: 100%;
    }
    .col-md-pull-11 {
      right: 91.66666667%;
    }
    .col-md-pull-10 {
      right: 83.33333333%;
    }
    .col-md-pull-9 {
      right: 75%;
    }
    .col-md-pull-8 {
      right: 66.66666667%;
    }
    .col-md-pull-7 {
      right: 58.33333333%;
    }
    .col-md-pull-6 {
      right: 50%;
    }
    .col-md-pull-5 {
      right: 41.66666667%;
    }
    .col-md-pull-4 {
      right: 33.33333333%;
    }
    .col-md-pull-3 {
      right: 25%;
    }
    .col-md-pull-2 {
      right: 16.66666667%;
    }
    .col-md-pull-1 {
      right: 8.33333333%;
    }
    .col-md-pull-0 {
      right: auto;
    }
    .col-md-push-12 {
      left: 100%;
    }
    .col-md-push-11 {
      left: 91.66666667%;
    }
    .col-md-push-10 {
      left: 83.33333333%;
    }
    .col-md-push-9 {
      left: 75%;
    }
    .col-md-push-8 {
      left: 66.66666667%;
    }
    .col-md-push-7 {
      left: 58.33333333%;
    }
    .col-md-push-6 {
      left: 50%;
    }
    .col-md-push-5 {
      left: 41.66666667%;
    }
    .col-md-push-4 {
      left: 33.33333333%;
    }
    .col-md-push-3 {
      left: 25%;
    }
    .col-md-push-2 {
      left: 16.66666667%;
    }
    .col-md-push-1 {
      left: 8.33333333%;
    }
    .col-md-push-0 {
      left: auto;
    }
    .col-md-offset-12 {
      margin-left: 100%;
    }
    .col-md-offset-11 {
      margin-left: 91.66666667%;
    }
    .col-md-offset-10 {
      margin-left: 83.33333333%;
    }
    .col-md-offset-9 {
      margin-left: 75%;
    }
    .col-md-offset-8 {
      margin-left: 66.66666667%;
    }
    .col-md-offset-7 {
      margin-left: 58.33333333%;
    }
    .col-md-offset-6 {
      margin-left: 50%;
    }
    .col-md-offset-5 {
      margin-left: 41.66666667%;
    }
    .col-md-offset-4 {
      margin-left: 33.33333333%;
    }
    .col-md-offset-3 {
      margin-left: 25%;
    }
    .col-md-offset-2 {
      margin-left: 16.66666667%;
    }
    .col-md-offset-1 {
      margin-left: 8.33333333%;
    }
    .col-md-offset-0 {
      margin-left: 0;
    }
  }
  @media (min-width: 1200px) {
    .col-lg-1,
    .col-lg-2,
    .col-lg-3,
    .col-lg-4,
    .col-lg-5,
    .col-lg-6,
    .col-lg-7,
    .col-lg-8,
    .col-lg-9,
    .col-lg-10,
    .col-lg-11,
    .col-lg-12 {
      float: left;
    }
    .col-lg-12 {
      width: 100%;
    }
    .col-lg-11 {
      width: 91.66666667%;
    }
    .col-lg-10 {
      width: 83.33333333%;
    }
    .col-lg-9 {
      width: 75%;
    }
    .col-lg-8 {
      width: 66.66666667%;
    }
    .col-lg-7 {
      width: 58.33333333%;
    }
    .col-lg-6 {
      width: 50%;
    }
    .col-lg-5 {
      width: 41.66666667%;
    }
    .col-lg-4 {
      width: 33.33333333%;
    }
    .col-lg-3 {
      width: 25%;
    }
    .col-lg-2 {
      width: 16.66666667%;
    }
    .col-lg-1 {
      width: 8.33333333%;
    }
    .col-lg-pull-12 {
      right: 100%;
    }
    .col-lg-pull-11 {
      right: 91.66666667%;
    }
    .col-lg-pull-10 {
      right: 83.33333333%;
    }
    .col-lg-pull-9 {
      right: 75%;
    }
    .col-lg-pull-8 {
      right: 66.66666667%;
    }
    .col-lg-pull-7 {
      right: 58.33333333%;
    }
    .col-lg-pull-6 {
      right: 50%;
    }
    .col-lg-pull-5 {
      right: 41.66666667%;
    }
    .col-lg-pull-4 {
      right: 33.33333333%;
    }
    .col-lg-pull-3 {
      right: 25%;
    }
    .col-lg-pull-2 {
      right: 16.66666667%;
    }
    .col-lg-pull-1 {
      right: 8.33333333%;
    }
    .col-lg-pull-0 {
      right: auto;
    }
    .col-lg-push-12 {
      left: 100%;
    }
    .col-lg-push-11 {
      left: 91.66666667%;
    }
    .col-lg-push-10 {
      left: 83.33333333%;
    }
    .col-lg-push-9 {
      left: 75%;
    }
    .col-lg-push-8 {
      left: 66.66666667%;
    }
    .col-lg-push-7 {
      left: 58.33333333%;
    }
    .col-lg-push-6 {
      left: 50%;
    }
    .col-lg-push-5 {
      left: 41.66666667%;
    }
    .col-lg-push-4 {
      left: 33.33333333%;
    }
    .col-lg-push-3 {
      left: 25%;
    }
    .col-lg-push-2 {
      left: 16.66666667%;
    }
    .col-lg-push-1 {
      left: 8.33333333%;
    }
    .col-lg-push-0 {
      left: auto;
    }
    .col-lg-offset-12 {
      margin-left: 100%;
    }
    .col-lg-offset-11 {
      margin-left: 91.66666667%;
    }
    .col-lg-offset-10 {
      margin-left: 83.33333333%;
    }
    .col-lg-offset-9 {
      margin-left: 75%;
    }
    .col-lg-offset-8 {
      margin-left: 66.66666667%;
    }
    .col-lg-offset-7 {
      margin-left: 58.33333333%;
    }
    .col-lg-offset-6 {
      margin-left: 50%;
    }
    .col-lg-offset-5 {
      margin-left: 41.66666667%;
    }
    .col-lg-offset-4 {
      margin-left: 33.33333333%;
    }
    .col-lg-offset-3 {
      margin-left: 25%;
    }
    .col-lg-offset-2 {
      margin-left: 16.66666667%;
    }
    .col-lg-offset-1 {
      margin-left: 8.33333333%;
    }
    .col-lg-offset-0 {
      margin-left: 0;
    }
  }

  .col-td-xs-1,
  .col-td-sm-1,
  .col-td-md-1,
  .col-td-lg-1,
  .col-td-xs-2,
  .col-td-sm-2,
  .col-td-md-2,
  .col-td-lg-2,
  .col-td-xs-3,
  .col-td-sm-3,
  .col-td-md-3,
  .col-td-lg-3,
  .col-td-xs-4,
  .col-td-sm-4,
  .col-td-md-4,
  .col-td-lg-4,
  .col-td-xs-5,
  .col-td-sm-5,
  .col-td-md-5,
  .col-td-lg-5,
  .col-td-xs-6,
  .col-td-sm-6,
  .col-td-md-6,
  .col-td-lg-6,
  .col-td-xs-7,
  .col-td-sm-7,
  .col-td-md-7,
  .col-td-lg-7,
  .col-td-xs-8,
  .col-td-sm-8,
  .col-td-md-8,
  .col-td-lg-8,
  .col-td-xs-9,
  .col-td-sm-9,
  .col-td-md-9,
  .col-td-lg-9,
  .col-td-xs-10,
  .col-td-sm-10,
  .col-td-md-10,
  .col-td-lg-10,
  .col-td-xs-11,
  .col-td-sm-11,
  .col-td-md-11,
  .col-td-lg-11,
  .col-td-xs-12,
  .col-td-sm-12,
  .col-td-md-12,
  .col-td-lg-12,
  .col-td-xs-13,
  .col-td-sm-13,
  .col-td-md-13,
  .col-td-lg-13,
  .col-td-xs-14,
  .col-td-sm-14,
  .col-td-md-14,
  .col-td-lg-14,
  .col-td-xs-15,
  .col-td-sm-15,
  .col-td-md-15,
  .col-td-lg-15,
  .col-td-xs-16,
  .col-td-sm-16,
  .col-td-md-16,
  .col-td-lg-16,
  .col-td-xs-17,
  .col-td-sm-17,
  .col-td-md-17,
  .col-td-lg-17,
  .col-td-xs-18,
  .col-td-sm-18,
  .col-td-md-18,
  .col-td-lg-18,
  .col-td-xs-19,
  .col-td-sm-19,
  .col-td-md-19,
  .col-td-lg-19,
  .col-td-xs-20,
  .col-td-sm-20,
  .col-td-md-20,
  .col-td-lg-20,
  .col-td-xs-21,
  .col-td-sm-21,
  .col-td-md-21,
  .col-td-lg-21,
  .col-td-xs-22,
  .col-td-sm-22,
  .col-td-md-22,
  .col-td-lg-22,
  .col-td-xs-23,
  .col-td-sm-23,
  .col-td-md-23,
  .col-td-lg-23,
  .col-td-xs-24,
  .col-td-sm-24,
  .col-td-md-24,
  .col-td-lg-24 {
    position: relative;
    min-height: 1px;
    padding-right: 10px;
    padding-left: 10px;
  }

  .col-td-xs-1,
  .col-td-xs-2,
  .col-td-xs-3,
  .col-td-xs-4,
  .col-td-xs-5,
  .col-td-xs-6,
  .col-td-xs-7,
  .col-td-xs-8,
  .col-td-xs-9,
  .col-td-xs-10,
  .col-td-xs-11,
  .col-td-xs-12,
  .col-td-xs-13,
  .col-td-xs-14,
  .col-td-xs-15,
  .col-td-xs-16,
  .col-td-xs-17,
  .col-td-xs-18,
  .col-td-xs-19,
  .col-td-xs-20,
  .col-td-xs-21,
  .col-td-xs-22,
  .col-td-xs-23 {
    float: left;
  }

  .col-td-xs-24 {
    width: 100%;
  }

  .col-td-xs-23 {
    width: 95.83333333333334%;
  }

  .col-td-xs-22 {
    width: 91.66666666666666%;
  }

  .col-td-xs-21 {
    width: 87.5%;
  }

  .col-td-xs-20 {
    width: 83.33333333333334%;
  }

  .col-td-xs-19 {
    width: 79.16666666666666%;
  }

  .col-td-xs-18 {
    width: 75%;
  }

  .col-td-xs-17 {
    width: 70.83333333333334%;
  }

  .col-td-xs-16 {
    width: 66.66666666666666%;
  }

  .col-td-xs-15 {
    width: 62.5%;
  }

  .col-td-xs-14 {
    width: 58.333333333333336%;
  }

  .col-td-xs-13 {
    width: 54.166666666666664%;
  }

  .col-td-xs-12 {
    width: 50%;
  }

  .col-td-xs-11 {
    width: 45.83333333333333%;
  }

  .col-td-xs-10 {
    width: 41.66666666666667%;
  }

  .col-td-xs-9 {
    width: 37.5%;
  }

  .col-td-xs-8 {
    width: 33.33333333333333%;
  }

  .col-td-xs-7 {
    width: 29.166666666666668%;
  }

  .col-td-xs-6 {
    width: 25%;
  }

  .col-td-xs-5 {
    width: 20.833333333333336%;
  }

  .col-td-xs-4 {
    width: 16.666666666666664%;
  }

  .col-td-xs-3 {
    width: 12.5%;
  }

  .col-td-xs-2 {
    width: 8.333333333333332%;
  }

  .col-td-xs-1 {
    width: 4.166666666666666%;
  }

  .col-td-xs-pull-24 {
    right: 100%;
  }

  .col-td-xs-pull-23 {
    right: 95.83333333333334%;
  }

  .col-td-xs-pull-22 {
    right: 91.66666666666666%;
  }

  .col-td-xs-pull-21 {
    right: 87.5%;
  }

  .col-td-xs-pull-20 {
    right: 83.33333333333334%;
  }

  .col-td-xs-pull-19 {
    right: 79.16666666666666%;
  }

  .col-td-xs-pull-18 {
    right: 75%;
  }

  .col-td-xs-pull-17 {
    right: 70.83333333333334%;
  }

  .col-td-xs-pull-16 {
    right: 66.66666666666666%;
  }

  .col-td-xs-pull-15 {
    right: 62.5%;
  }

  .col-td-xs-pull-14 {
    right: 58.333333333333336%;
  }

  .col-td-xs-pull-13 {
    right: 54.166666666666664%;
  }

  .col-td-xs-pull-12 {
    right: 50%;
  }

  .col-td-xs-pull-11 {
    right: 45.83333333333333%;
  }

  .col-td-xs-pull-10 {
    right: 41.66666666666667%;
  }

  .col-td-xs-pull-9 {
    right: 37.5%;
  }

  .col-td-xs-pull-8 {
    right: 33.33333333333333%;
  }

  .col-td-xs-pull-7 {
    right: 29.166666666666668%;
  }

  .col-td-xs-pull-6 {
    right: 25%;
  }

  .col-td-xs-pull-5 {
    right: 20.833333333333336%;
  }

  .col-td-xs-pull-4 {
    right: 16.666666666666664%;
  }

  .col-td-xs-pull-3 {
    right: 12.5%;
  }

  .col-td-xs-pull-2 {
    right: 8.333333333333332%;
  }

  .col-td-xs-pull-1 {
    right: 4.166666666666666%;
  }

  .col-td-xs-push-24 {
    left: 100%;
  }

  .col-td-xs-push-23 {
    left: 95.83333333333334%;
  }

  .col-td-xs-push-22 {
    left: 91.66666666666666%;
  }

  .col-td-xs-push-21 {
    left: 87.5%;
  }

  .col-td-xs-push-20 {
    left: 83.33333333333334%;
  }

  .col-td-xs-push-19 {
    left: 79.16666666666666%;
  }

  .col-td-xs-push-18 {
    left: 75%;
  }

  .col-td-xs-push-17 {
    left: 70.83333333333334%;
  }

  .col-td-xs-push-16 {
    left: 66.66666666666666%;
  }

  .col-td-xs-push-15 {
    left: 62.5%;
  }

  .col-td-xs-push-14 {
    left: 58.333333333333336%;
  }

  .col-td-xs-push-13 {
    left: 54.166666666666664%;
  }

  .col-td-xs-push-12 {
    left: 50%;
  }

  .col-td-xs-push-11 {
    left: 45.83333333333333%;
  }

  .col-td-xs-push-10 {
    left: 41.66666666666667%;
  }

  .col-td-xs-push-9 {
    left: 37.5%;
  }

  .col-td-xs-push-8 {
    left: 33.33333333333333%;
  }

  .col-td-xs-push-7 {
    left: 29.166666666666668%;
  }

  .col-td-xs-push-6 {
    left: 25%;
  }

  .col-td-xs-push-5 {
    left: 20.833333333333336%;
  }

  .col-td-xs-push-4 {
    left: 16.666666666666664%;
  }

  .col-td-xs-push-3 {
    left: 12.5%;
  }

  .col-td-xs-push-2 {
    left: 8.333333333333332%;
  }

  .col-td-xs-push-1 {
    left: 4.166666666666666%;
  }

  .col-td-xs-offset-24 {
    margin-left: 100%;
  }

  .col-td-xs-offset-23 {
    margin-left: 95.83333333333334%;
  }

  .col-td-xs-offset-22 {
    margin-left: 91.66666666666666%;
  }

  .col-td-xs-offset-21 {
    margin-left: 87.5%;
  }

  .col-td-xs-offset-20 {
    margin-left: 83.33333333333334%;
  }

  .col-td-xs-offset-19 {
    margin-left: 79.16666666666666%;
  }

  .col-td-xs-offset-18 {
    margin-left: 75%;
  }

  .col-td-xs-offset-17 {
    margin-left: 70.83333333333334%;
  }

  .col-td-xs-offset-16 {
    margin-left: 66.66666666666666%;
  }

  .col-td-xs-offset-15 {
    margin-left: 62.5%;
  }

  .col-td-xs-offset-14 {
    margin-left: 58.333333333333336%;
  }

  .col-td-xs-offset-13 {
    margin-left: 54.166666666666664%;
  }

  .col-td-xs-offset-12 {
    margin-left: 50%;
  }

  .col-td-xs-offset-11 {
    margin-left: 45.83333333333333%;
  }

  .col-td-xs-offset-10 {
    margin-left: 41.66666666666667%;
  }

  .col-td-xs-offset-9 {
    margin-left: 37.5%;
  }

  .col-td-xs-offset-8 {
    margin-left: 33.33333333333333%;
  }

  .col-td-xs-offset-7 {
    margin-left: 29.166666666666668%;
  }

  .col-td-xs-offset-6 {
    margin-left: 25%;
  }

  .col-td-xs-offset-5 {
    margin-left: 20.833333333333336%;
  }

  .col-td-xs-offset-4 {
    margin-left: 16.666666666666664%;
  }

  .col-td-xs-offset-3 {
    margin-left: 12.5%;
  }

  .col-td-xs-offset-2 {
    margin-left: 8.333333333333332%;
  }

  .col-td-xs-offset-1 {
    margin-left: 4.166666666666666%;
  }

  @media (min-width: 768px) {
    .container {
      width: 750px;
    }
    .col-td-sm-1,
    .col-td-sm-2,
    .col-td-sm-3,
    .col-td-sm-4,
    .col-td-sm-5,
    .col-td-sm-6,
    .col-td-sm-7,
    .col-td-sm-8,
    .col-td-sm-9,
    .col-td-sm-10,
    .col-td-sm-11,
    .col-td-sm-12,
    .col-td-sm-13,
    .col-td-sm-14,
    .col-td-sm-15,
    .col-td-sm-16,
    .col-td-sm-17,
    .col-td-sm-18,
    .col-td-sm-19,
    .col-td-sm-20,
    .col-td-sm-21,
    .col-td-sm-22,
    .col-td-sm-23 {
      float: left;
    }
    .col-td-sm-24 {
      width: 100%;
    }
    .col-td-sm-23 {
      width: 95.83333333333334%;
    }
    .col-td-sm-22 {
      width: 91.66666666666666%;
    }
    .col-td-sm-21 {
      width: 87.5%;
    }
    .col-td-sm-20 {
      width: 83.33333333333334%;
    }
    .col-td-sm-19 {
      width: 79.16666666666666%;
    }
    .col-td-sm-18 {
      width: 75%;
    }
    .col-td-sm-17 {
      width: 70.83333333333334%;
    }
    .col-td-sm-16 {
      width: 66.66666666666666%;
    }
    .col-td-sm-15 {
      width: 62.5%;
    }
    .col-td-sm-14 {
      width: 58.333333333333336%;
    }
    .col-td-sm-13 {
      width: 54.166666666666664%;
    }
    .col-td-sm-12 {
      width: 50%;
    }
    .col-td-sm-11 {
      width: 45.83333333333333%;
    }
    .col-td-sm-10 {
      width: 41.66666666666667%;
    }
    .col-td-sm-9 {
      width: 37.5%;
    }
    .col-td-sm-8 {
      width: 33.33333333333333%;
    }
    .col-td-sm-7 {
      width: 29.166666666666668%;
    }
    .col-td-sm-6 {
      width: 25%;
    }
    .col-td-sm-5 {
      width: 20.833333333333336%;
    }
    .col-td-sm-4 {
      width: 16.666666666666664%;
    }
    .col-td-sm-3 {
      width: 12.5%;
    }
    .col-td-sm-2 {
      width: 8.333333333333332%;
    }
    .col-td-sm-1 {
      width: 4.166666666666666%;
    }
    .col-td-sm-pull-24 {
      right: 100%;
    }
    .col-td-sm-pull-23 {
      right: 95.83333333333334%;
    }
    .col-td-sm-pull-22 {
      right: 91.66666666666666%;
    }
    .col-td-sm-pull-21 {
      right: 87.5%;
    }
    .col-td-sm-pull-20 {
      right: 83.33333333333334%;
    }
    .col-td-sm-pull-19 {
      right: 79.16666666666666%;
    }
    .col-td-sm-pull-18 {
      right: 75%;
    }
    .col-td-sm-pull-17 {
      right: 70.83333333333334%;
    }
    .col-td-sm-pull-16 {
      right: 66.66666666666666%;
    }
    .col-td-sm-pull-15 {
      right: 62.5%;
    }
    .col-td-sm-pull-14 {
      right: 58.333333333333336%;
    }
    .col-td-sm-pull-13 {
      right: 54.166666666666664%;
    }
    .col-td-sm-pull-12 {
      right: 50%;
    }
    .col-td-sm-pull-11 {
      right: 45.83333333333333%;
    }
    .col-td-sm-pull-10 {
      right: 41.66666666666667%;
    }
    .col-td-sm-pull-9 {
      right: 37.5%;
    }
    .col-td-sm-pull-8 {
      right: 33.33333333333333%;
    }
    .col-td-sm-pull-7 {
      right: 29.166666666666668%;
    }
    .col-td-sm-pull-6 {
      right: 25%;
    }
    .col-td-sm-pull-5 {
      right: 20.833333333333336%;
    }
    .col-td-sm-pull-4 {
      right: 16.666666666666664%;
    }
    .col-td-sm-pull-3 {
      right: 12.5%;
    }
    .col-td-sm-pull-2 {
      right: 8.333333333333332%;
    }
    .col-td-sm-pull-1 {
      right: 4.166666666666666%;
    }
    .col-td-sm-push-24 {
      left: 100%;
    }
    .col-td-sm-push-23 {
      left: 95.83333333333334%;
    }
    .col-td-sm-push-22 {
      left: 91.66666666666666%;
    }
    .col-td-sm-push-21 {
      left: 87.5%;
    }
    .col-td-sm-push-20 {
      left: 83.33333333333334%;
    }
    .col-td-sm-push-19 {
      left: 79.16666666666666%;
    }
    .col-td-sm-push-18 {
      left: 75%;
    }
    .col-td-sm-push-17 {
      left: 70.83333333333334%;
    }
    .col-td-sm-push-16 {
      left: 66.66666666666666%;
    }
    .col-td-sm-push-15 {
      left: 62.5%;
    }
    .col-td-sm-push-14 {
      left: 58.333333333333336%;
    }
    .col-td-sm-push-13 {
      left: 54.166666666666664%;
    }
    .col-td-sm-push-12 {
      left: 50%;
    }
    .col-td-sm-push-11 {
      left: 45.83333333333333%;
    }
    .col-td-sm-push-10 {
      left: 41.66666666666667%;
    }
    .col-td-sm-push-9 {
      left: 37.5%;
    }
    .col-td-sm-push-8 {
      left: 33.33333333333333%;
    }
    .col-td-sm-push-7 {
      left: 29.166666666666668%;
    }
    .col-td-sm-push-6 {
      left: 25%;
    }
    .col-td-sm-push-5 {
      left: 20.833333333333336%;
    }
    .col-td-sm-push-4 {
      left: 16.666666666666664%;
    }
    .col-td-sm-push-3 {
      left: 12.5%;
    }
    .col-td-sm-push-2 {
      left: 8.333333333333332%;
    }
    .col-td-sm-push-1 {
      left: 4.166666666666666%;
    }
    .col-td-sm-offset-24 {
      margin-left: 100%;
    }
    .col-td-sm-offset-23 {
      margin-left: 95.83333333333334%;
    }
    .col-td-sm-offset-22 {
      margin-left: 91.66666666666666%;
    }
    .col-td-sm-offset-21 {
      margin-left: 87.5%;
    }
    .col-td-sm-offset-20 {
      margin-left: 83.33333333333334%;
    }
    .col-td-sm-offset-19 {
      margin-left: 79.16666666666666%;
    }
    .col-td-sm-offset-18 {
      margin-left: 75%;
    }
    .col-td-sm-offset-17 {
      margin-left: 70.83333333333334%;
    }
    .col-td-sm-offset-16 {
      margin-left: 66.66666666666666%;
    }
    .col-td-sm-offset-15 {
      margin-left: 62.5%;
    }
    .col-td-sm-offset-14 {
      margin-left: 58.333333333333336%;
    }
    .col-td-sm-offset-13 {
      margin-left: 54.166666666666664%;
    }
    .col-td-sm-offset-12 {
      margin-left: 50%;
    }
    .col-td-sm-offset-11 {
      margin-left: 45.83333333333333%;
    }
    .col-td-sm-offset-10 {
      margin-left: 41.66666666666667%;
    }
    .col-td-sm-offset-9 {
      margin-left: 37.5%;
    }
    .col-td-sm-offset-8 {
      margin-left: 33.33333333333333%;
    }
    .col-td-sm-offset-7 {
      margin-left: 29.166666666666668%;
    }
    .col-td-sm-offset-6 {
      margin-left: 25%;
    }
    .col-td-sm-offset-5 {
      margin-left: 20.833333333333336%;
    }
    .col-td-sm-offset-4 {
      margin-left: 16.666666666666664%;
    }
    .col-td-sm-offset-3 {
      margin-left: 12.5%;
    }
    .col-td-sm-offset-2 {
      margin-left: 8.333333333333332%;
    }
    .col-td-sm-offset-1 {
      margin-left: 4.166666666666666%;
    }
  }

  @media (min-width: 992px) {
    .container {
      width: 970px;
    }
    .col-td-md-1,
    .col-td-md-2,
    .col-td-md-3,
    .col-td-md-4,
    .col-td-md-5,
    .col-td-md-6,
    .col-td-md-7,
    .col-td-md-8,
    .col-td-md-9,
    .col-td-md-10,
    .col-td-md-11,
    .col-td-md-12,
    .col-td-md-13,
    .col-td-md-14,
    .col-td-md-15,
    .col-td-md-16,
    .col-td-md-17,
    .col-td-md-18,
    .col-td-md-19,
    .col-td-md-20,
    .col-td-md-21,
    .col-td-md-22,
    .col-td-md-23 {
      float: left;
    }
    .col-td-md-24 {
      width: 100%;
    }
    .col-td-md-23 {
      width: 95.83333333333334%;
    }
    .col-td-md-22 {
      width: 91.66666666666666%;
    }
    .col-td-md-21 {
      width: 87.5%;
    }
    .col-td-md-20 {
      width: 83.33333333333334%;
    }
    .col-td-md-19 {
      width: 79.16666666666666%;
    }
    .col-td-md-18 {
      width: 75%;
    }
    .col-td-md-17 {
      width: 70.83333333333334%;
    }
    .col-td-md-16 {
      width: 66.66666666666666%;
    }
    .col-td-md-15 {
      width: 62.5%;
    }
    .col-td-md-14 {
      width: 58.333333333333336%;
    }
    .col-td-md-13 {
      width: 54.166666666666664%;
    }
    .col-td-md-12 {
      width: 50%;
    }
    .col-td-md-11 {
      width: 45.83333333333333%;
    }
    .col-td-md-10 {
      width: 41.66666666666667%;
    }
    .col-td-md-9 {
      width: 37.5%;
    }
    .col-td-md-8 {
      width: 33.33333333333333%;
    }
    .col-td-md-7 {
      width: 29.166666666666668%;
    }
    .col-td-md-6 {
      width: 25%;
    }
    .col-td-md-5 {
      width: 20.833333333333336%;
    }
    .col-td-md-4 {
      width: 16.666666666666664%;
    }
    .col-td-md-3 {
      width: 12.5%;
    }
    .col-td-md-2 {
      width: 8.333333333333332%;
    }
    .col-td-md-1 {
      width: 4.166666666666666%;
    }
    .col-td-md-pull-24 {
      right: 100%;
    }
    .col-td-md-pull-23 {
      right: 95.83333333333334%;
    }
    .col-td-md-pull-22 {
      right: 91.66666666666666%;
    }
    .col-td-md-pull-21 {
      right: 87.5%;
    }
    .col-td-md-pull-20 {
      right: 83.33333333333334%;
    }
    .col-td-md-pull-19 {
      right: 79.16666666666666%;
    }
    .col-td-md-pull-18 {
      right: 75%;
    }
    .col-td-md-pull-17 {
      right: 70.83333333333334%;
    }
    .col-td-md-pull-16 {
      right: 66.66666666666666%;
    }
    .col-td-md-pull-15 {
      right: 62.5%;
    }
    .col-td-md-pull-14 {
      right: 58.333333333333336%;
    }
    .col-td-md-pull-13 {
      right: 54.166666666666664%;
    }
    .col-td-md-pull-12 {
      right: 50%;
    }
    .col-td-md-pull-11 {
      right: 45.83333333333333%;
    }
    .col-td-md-pull-10 {
      right: 41.66666666666667%;
    }
    .col-td-md-pull-9 {
      right: 37.5%;
    }
    .col-td-md-pull-8 {
      right: 33.33333333333333%;
    }
    .col-td-md-pull-7 {
      right: 29.166666666666668%;
    }
    .col-td-md-pull-6 {
      right: 25%;
    }
    .col-td-md-pull-5 {
      right: 20.833333333333336%;
    }
    .col-td-md-pull-4 {
      right: 16.666666666666664%;
    }
    .col-td-md-pull-3 {
      right: 12.5%;
    }
    .col-td-md-pull-2 {
      right: 8.333333333333332%;
    }
    .col-td-md-pull-1 {
      right: 4.166666666666666%;
    }
    .col-td-md-push-24 {
      left: 100%;
    }
    .col-td-md-push-23 {
      left: 95.83333333333334%;
    }
    .col-td-md-push-22 {
      left: 91.66666666666666%;
    }
    .col-td-md-push-21 {
      left: 87.5%;
    }
    .col-td-md-push-20 {
      left: 83.33333333333334%;
    }
    .col-td-md-push-19 {
      left: 79.16666666666666%;
    }
    .col-td-md-push-18 {
      left: 75%;
    }
    .col-td-md-push-17 {
      left: 70.83333333333334%;
    }
    .col-td-md-push-16 {
      left: 66.66666666666666%;
    }
    .col-td-md-push-15 {
      left: 62.5%;
    }
    .col-td-md-push-14 {
      left: 58.333333333333336%;
    }
    .col-td-md-push-13 {
      left: 54.166666666666664%;
    }
    .col-td-md-push-12 {
      left: 50%;
    }
    .col-td-md-push-11 {
      left: 45.83333333333333%;
    }
    .col-td-md-push-10 {
      left: 41.66666666666667%;
    }
    .col-td-md-push-9 {
      left: 37.5%;
    }
    .col-td-md-push-8 {
      left: 33.33333333333333%;
    }
    .col-td-md-push-7 {
      left: 29.166666666666668%;
    }
    .col-td-md-push-6 {
      left: 25%;
    }
    .col-td-md-push-5 {
      left: 20.833333333333336%;
    }
    .col-td-md-push-4 {
      left: 16.666666666666664%;
    }
    .col-td-md-push-3 {
      left: 12.5%;
    }
    .col-td-md-push-2 {
      left: 8.333333333333332%;
    }
    .col-td-md-push-1 {
      left: 4.166666666666666%;
    }
    .col-td-md-offset-24 {
      margin-left: 100%;
    }
    .col-td-md-offset-23 {
      margin-left: 95.83333333333334%;
    }
    .col-td-md-offset-22 {
      margin-left: 91.66666666666666%;
    }
    .col-td-md-offset-21 {
      margin-left: 87.5%;
    }
    .col-td-md-offset-20 {
      margin-left: 83.33333333333334%;
    }
    .col-td-md-offset-19 {
      margin-left: 79.16666666666666%;
    }
    .col-td-md-offset-18 {
      margin-left: 75%;
    }
    .col-td-md-offset-17 {
      margin-left: 70.83333333333334%;
    }
    .col-td-md-offset-16 {
      margin-left: 66.66666666666666%;
    }
    .col-td-md-offset-15 {
      margin-left: 62.5%;
    }
    .col-td-md-offset-14 {
      margin-left: 58.333333333333336%;
    }
    .col-td-md-offset-13 {
      margin-left: 54.166666666666664%;
    }
    .col-td-md-offset-12 {
      margin-left: 50%;
    }
    .col-td-md-offset-11 {
      margin-left: 45.83333333333333%;
    }
    .col-td-md-offset-10 {
      margin-left: 41.66666666666667%;
    }
    .col-td-md-offset-9 {
      margin-left: 37.5%;
    }
    .col-td-md-offset-8 {
      margin-left: 33.33333333333333%;
    }
    .col-td-md-offset-7 {
      margin-left: 29.166666666666668%;
    }
    .col-td-md-offset-6 {
      margin-left: 25%;
    }
    .col-td-md-offset-5 {
      margin-left: 20.833333333333336%;
    }
    .col-td-md-offset-4 {
      margin-left: 16.666666666666664%;
    }
    .col-td-md-offset-3 {
      margin-left: 12.5%;
    }
    .col-td-md-offset-2 {
      margin-left: 8.333333333333332%;
    }
    .col-td-md-offset-1 {
      margin-left: 4.166666666666666%;
    }
  }

  @media (min-width: 1200px) {
    .container {
      width: 1170px;
    }
    .col-td-lg-1,
    .col-td-lg-2,
    .col-td-lg-3,
    .col-td-lg-4,
    .col-td-lg-5,
    .col-td-lg-6,
    .col-td-lg-7,
    .col-td-lg-8,
    .col-td-lg-9,
    .col-td-lg-10,
    .col-td-lg-11,
    .col-td-lg-12,
    .col-td-lg-13,
    .col-td-lg-14,
    .col-td-lg-15,
    .col-td-lg-16,
    .col-td-lg-17,
    .col-td-lg-18,
    .col-td-lg-19,
    .col-td-lg-20,
    .col-td-lg-21,
    .col-td-lg-22,
    .col-td-lg-23 {
      float: left;
    }
    .col-td-lg-24 {
      width: 100%;
    }
    .col-td-lg-23 {
      width: 95.83333333333334%;
    }
    .col-td-lg-22 {
      width: 91.66666666666666%;
    }
    .col-td-lg-21 {
      width: 87.5%;
    }
    .col-td-lg-20 {
      width: 83.33333333333334%;
    }
    .col-td-lg-19 {
      width: 79.16666666666666%;
    }
    .col-td-lg-18 {
      width: 75%;
    }
    .col-td-lg-17 {
      width: 70.83333333333334%;
    }
    .col-td-lg-16 {
      width: 66.66666666666666%;
    }
    .col-td-lg-15 {
      width: 62.5%;
    }
    .col-td-lg-14 {
      width: 58.333333333333336%;
    }
    .col-td-lg-13 {
      width: 54.166666666666664%;
    }
    .col-td-lg-12 {
      width: 50%;
    }
    .col-td-lg-11 {
      width: 45.83333333333333%;
    }
    .col-td-lg-10 {
      width: 41.66666666666667%;
    }
    .col-td-lg-9 {
      width: 37.5%;
    }
    .col-td-lg-8 {
      width: 33.33333333333333%;
    }
    .col-td-lg-7 {
      width: 29.166666666666668%;
    }
    .col-td-lg-6 {
      width: 25%;
    }
    .col-td-lg-5 {
      width: 20.833333333333336%;
    }
    .col-td-lg-4 {
      width: 16.666666666666664%;
    }
    .col-td-lg-3 {
      width: 12.5%;
    }
    .col-td-lg-2 {
      width: 8.333333333333332%;
    }
    .col-td-lg-1 {
      width: 4.166666666666666%;
    }
    .col-td-lg-pull-24 {
      right: 100%;
    }
    .col-td-lg-pull-23 {
      right: 95.83333333333334%;
    }
    .col-td-lg-pull-22 {
      right: 91.66666666666666%;
    }
    .col-td-lg-pull-21 {
      right: 87.5%;
    }
    .col-td-lg-pull-20 {
      right: 83.33333333333334%;
    }
    .col-td-lg-pull-19 {
      right: 79.16666666666666%;
    }
    .col-td-lg-pull-18 {
      right: 75%;
    }
    .col-td-lg-pull-17 {
      right: 70.83333333333334%;
    }
    .col-td-lg-pull-16 {
      right: 66.66666666666666%;
    }
    .col-td-lg-pull-15 {
      right: 62.5%;
    }
    .col-td-lg-pull-14 {
      right: 58.333333333333336%;
    }
    .col-td-lg-pull-13 {
      right: 54.166666666666664%;
    }
    .col-td-lg-pull-12 {
      right: 50%;
    }
    .col-td-lg-pull-11 {
      right: 45.83333333333333%;
    }
    .col-td-lg-pull-10 {
      right: 41.66666666666667%;
    }
    .col-td-lg-pull-9 {
      right: 37.5%;
    }
    .col-td-lg-pull-8 {
      right: 33.33333333333333%;
    }
    .col-td-lg-pull-7 {
      right: 29.166666666666668%;
    }
    .col-td-lg-pull-6 {
      right: 25%;
    }
    .col-td-lg-pull-5 {
      right: 20.833333333333336%;
    }
    .col-td-lg-pull-4 {
      right: 16.666666666666664%;
    }
    .col-td-lg-pull-3 {
      right: 12.5%;
    }
    .col-td-lg-pull-2 {
      right: 8.333333333333332%;
    }
    .col-td-lg-pull-1 {
      right: 4.166666666666666%;
    }
    .col-td-lg-push-24 {
      left: 100%;
    }
    .col-td-lg-push-23 {
      left: 95.83333333333334%;
    }
    .col-td-lg-push-22 {
      left: 91.66666666666666%;
    }
    .col-td-lg-push-21 {
      left: 87.5%;
    }
    .col-td-lg-push-20 {
      left: 83.33333333333334%;
    }
    .col-td-lg-push-19 {
      left: 79.16666666666666%;
    }
    .col-td-lg-push-18 {
      left: 75%;
    }
    .col-td-lg-push-17 {
      left: 70.83333333333334%;
    }
    .col-td-lg-push-16 {
      left: 66.66666666666666%;
    }
    .col-td-lg-push-15 {
      left: 62.5%;
    }
    .col-td-lg-push-14 {
      left: 58.333333333333336%;
    }
    .col-td-lg-push-13 {
      left: 54.166666666666664%;
    }
    .col-td-lg-push-12 {
      left: 50%;
    }
    .col-td-lg-push-11 {
      left: 45.83333333333333%;
    }
    .col-td-lg-push-10 {
      left: 41.66666666666667%;
    }
    .col-td-lg-push-9 {
      left: 37.5%;
    }
    .col-td-lg-push-8 {
      left: 33.33333333333333%;
    }
    .col-td-lg-push-7 {
      left: 29.166666666666668%;
    }
    .col-td-lg-push-6 {
      left: 25%;
    }
    .col-td-lg-push-5 {
      left: 20.833333333333336%;
    }
    .col-td-lg-push-4 {
      left: 16.666666666666664%;
    }
    .col-td-lg-push-3 {
      left: 12.5%;
    }
    .col-td-lg-push-2 {
      left: 8.333333333333332%;
    }
    .col-td-lg-push-1 {
      left: 4.166666666666666%;
    }
    .col-td-lg-offset-24 {
      margin-left: 100%;
    }
    .col-td-lg-offset-23 {
      margin-left: 95.83333333333334%;
    }
    .col-td-lg-offset-22 {
      margin-left: 91.66666666666666%;
    }
    .col-td-lg-offset-21 {
      margin-left: 87.5%;
    }
    .col-td-lg-offset-20 {
      margin-left: 83.33333333333334%;
    }
    .col-td-lg-offset-19 {
      margin-left: 79.16666666666666%;
    }
    .col-td-lg-offset-18 {
      margin-left: 75%;
    }
    .col-td-lg-offset-17 {
      margin-left: 70.83333333333334%;
    }
    .col-td-lg-offset-16 {
      margin-left: 66.66666666666666%;
    }
    .col-td-lg-offset-15 {
      margin-left: 62.5%;
    }
    .col-td-lg-offset-14 {
      margin-left: 58.333333333333336%;
    }
    .col-td-lg-offset-13 {
      margin-left: 54.166666666666664%;
    }
    .col-td-lg-offset-12 {
      margin-left: 50%;
    }
    .col-td-lg-offset-11 {
      margin-left: 45.83333333333333%;
    }
    .col-td-lg-offset-10 {
      margin-left: 41.66666666666667%;
    }
    .col-td-lg-offset-9 {
      margin-left: 37.5%;
    }
    .col-td-lg-offset-8 {
      margin-left: 33.33333333333333%;
    }
    .col-td-lg-offset-7 {
      margin-left: 29.166666666666668%;
    }
    .col-td-lg-offset-6 {
      margin-left: 25%;
    }
    .col-td-lg-offset-5 {
      margin-left: 20.833333333333336%;
    }
    .col-td-lg-offset-4 {
      margin-left: 16.666666666666664%;
    }
    .col-td-lg-offset-3 {
      margin-left: 12.5%;
    }
    .col-td-lg-offset-2 {
      margin-left: 8.333333333333332%;
    }
    .col-td-lg-offset-1 {
      margin-left: 4.166666666666666%;
    }
  }

  .six-dashboard {
    padding-bottom: 0px;
  }

  .clearfix:before,
  .clearfix:after {
    display: table;
    content: ' ';
  }

  .clearfix:after {
    clear: both;
  }

  .toolbar {
    margin-bottom: 10px;
    padding-bottom: 0px;
  }

  .list-group {
    padding: 0px;
    margin: 0px;
    list-style-type: none;
  }

  .list-group-fixed {
    padding: 0px;
    margin: 0px;
    height: 220px;
    list-style-type: none;
    overflow: scroll;
    overflow-y: scroll;
    overflow-x: hidden;
    margin-bottom: 10px;
  }

  .list-group-fixed.single {
    height: 420px;
  }

  .list-group-fixed-toolbar {
    padding: 0px;
    margin: 0px;
    height: 610px;
    list-style-type: none;
    overflow: scroll;
    overflow-y: scroll;
    overflow-x: hidden;
    margin-bottom: 10px;
  }

  .list-group-fixed-toolbar-thead {
    padding: 0px;
    margin: 0px;
    height: 560px;
    list-style-type: none;
    overflow: scroll;
    overflow-y: scroll;
    overflow-x: hidden;
    margin-bottom: 10px;
  }

  .panel {
    margin-bottom: 20px;
    background-color: #fff;
    border: 0px solid transparent;
    border-radius: 4px;
    -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    height: 660px;
  }

  .panel.with-toolbar {
    margin-bottom: 20px;
    background-color: #fff;
    border: 0px solid transparent;
    border-radius: 4px;
    -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    height: 710px;
  }

  .panel.half.with-toolbar {
    margin-bottom: 20px;
    background-color: #fff;
    border: 0px solid transparent;
    border-radius: 4px;
    height: 350px;
  }

  .panel.topbar-panel {
    margin-top: 0px;
  }

  .panel.topbar-panel .panel-body {
    padding-bottom: 8px !important;
  }

  .panel.without-height {
    margin-bottom: 20px;
    background-color: #fff;
    border: 0px solid transparent;
    height: auto;
  }

  .panel-body {
    padding: 15px;
  }

  .panel-scroll-container {
    padding: 15px;
    overflow: hidden;
    overflow: scroll;
    overflow-y: scroll;
    overflow-x: hidden;
    height: 620px;
    position: relative;
    background-color: #efefef;
  }

  .panel.small-height {
    margin-bottom: 20px;
    background-color: #fff;
    border: 0px solid transparent;
    border-radius: 4px;
    -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    height: 350px;
  }

  .panel-heading {
    padding: 4px 15px;
    border-left: 8px solid #4fc1e9;
    border-bottom: 1px solid #efefef;
    border-top-left-radius: 3px;
    background-color: #f9f9f9;
    border-top-right-radius: 3px;
    font-size: 14px;
  }
  .panel-heading > .dropdown .dropdown-toggle {
    color: inherit;
  }
  .panel-title {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 16px;
    color: inherit;
  }
  .panel-title > a,
  .panel-title > small,
  .panel-title > .small,
  .panel-title > small > a,
  .panel-title > .small > a {
    color: inherit;
  }
  .panel-footer {
    padding: 10px 15px;
    background-color: #f5f5f5;
    border-top: 1px solid #ddd;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
  }

  .form-control {
    display: block;
    width: 100%;
    height: 30px;
    padding: 6px 12px;
    font-size: 14px;
    color: #555;
    line-height: 30px;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    -webkit-transition: border-color ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s;
    -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  }
  .form-control:focus {
    border-color: #66afe9;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
  }
  .form-control::-moz-placeholder {
    color: #999;
    opacity: 1;
  }
  .form-control:-ms-input-placeholder {
    color: #999;
  }
  .form-control::-webkit-input-placeholder {
    color: #999;
  }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0;
  }
  .form-control[disabled],
  .form-control[readonly],
  fieldset[disabled] .form-control {
    background-color: #eee;
    opacity: 1;
  }
  .form-control[disabled],
  fieldset[disabled] .form-control {
    cursor: not-allowed;
  }
  textarea.form-control {
    height: auto;
  }
  input[type='search'] {
    -webkit-appearance: none;
  }
  @media screen and (-webkit-min-device-pixel-ratio: 0) {
    input[type='date'].form-control,
    input[type='time'].form-control,
    input[type='datetime-local'].form-control,
    input[type='month'].form-control {
      line-height: 34px;
    }
    input[type='date'].input-sm,
    input[type='time'].input-sm,
    input[type='datetime-local'].input-sm,
    input[type='month'].input-sm,
    .input-group-sm input[type='date'],
    .input-group-sm input[type='time'],
    .input-group-sm input[type='datetime-local'],
    .input-group-sm input[type='month'] {
      line-height: 30px;
    }
    input[type='date'].input-lg,
    input[type='time'].input-lg,
    input[type='datetime-local'].input-lg,
    input[type='month'].input-lg,
    .input-group-lg input[type='date'],
    .input-group-lg input[type='time'],
    .input-group-lg input[type='datetime-local'],
    .input-group-lg input[type='month'] {
      line-height: 46px;
    }
  }

  .center-block {
    display: block;
    margin-right: auto;
    margin-left: auto;
  }
  .pull-right {
    float: right !important;
  }
  .pull-left {
    float: left !important;
  }
  .hide {
    display: none !important;
  }
  .show {
    display: block !important;
  }
  .invisible {
    visibility: hidden;
  }

  .form-group {
    margin-bottom: 0px;
  }

  .form-group.mb-10 {
    margin-bottom: 10px !important;
  }

  .form-group label {
    font-size: 12px;
    color: #878787;
  }

  .radio,
  .checkbox {
    position: relative;
    display: block;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .radio label,
  .checkbox label {
    min-height: 20px;
    padding-left: 20px;
    margin-bottom: 0;
    font-weight: normal;
    cursor: pointer;
  }
  .radio input[type='radio'],
  .radio-inline input[type='radio'],
  .checkbox input[type='checkbox'],
  .checkbox-inline input[type='checkbox'] {
    position: absolute;
    margin-left: -20px;
  }
  .radio + .radio,
  .checkbox + .checkbox {
    margin-top: -5px;
  }
  .radio-inline,
  .checkbox-inline {
    position: relative;
    display: inline-block;
    padding-left: 20px;
    margin-bottom: 0;
    font-weight: normal;
    vertical-align: middle;
    cursor: pointer;
  }
  .radio-inline + .radio-inline,
  .checkbox-inline + .checkbox-inline {
    margin-top: 0;
    margin-left: 10px;
  }
  input[type='radio'][disabled],
  input[type='checkbox'][disabled],
  input[type='radio'].disabled,
  input[type='checkbox'].disabled,
  fieldset[disabled] input[type='radio'],
  fieldset[disabled] input[type='checkbox'] {
    cursor: not-allowed;
  }
  .radio-inline.disabled,
  .checkbox-inline.disabled,
  fieldset[disabled] .radio-inline,
  fieldset[disabled] .checkbox-inline {
    cursor: not-allowed;
  }
  .radio.disabled label,
  .checkbox.disabled label,
  fieldset[disabled] .radio label,
  fieldset[disabled] .checkbox label {
    cursor: not-allowed;
  }
  .form-control-static {
    min-height: 34px;
    padding-top: 7px;
    padding-bottom: 7px;
    margin-bottom: 0;
  }
  .form-control-static.input-lg,
  .form-control-static.input-sm {
    padding-right: 0;
    padding-left: 0;
  }
  .input-sm {
    height: 30px;
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 3px;
  }
  select.input-sm {
    height: 30px;
    line-height: 30px;
  }
  textarea.input-sm,
  select[multiple].input-sm {
    height: auto;
  }
  .form-group-sm .form-control {
    height: 30px;
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 3px;
  }
  .form-group-sm select.form-control {
    height: 30px;
    line-height: 30px;
  }
  .form-group-sm textarea.form-control,
  .form-group-sm select[multiple].form-control {
    height: auto;
  }
  .form-group-sm .form-control-static {
    height: 30px;
    min-height: 32px;
    padding: 6px 10px;
    font-size: 12px;
    line-height: 1.5;
  }
  .input-lg {
    height: 46px;
    padding: 10px 16px;
    font-size: 18px;
    line-height: 1.3333333;
    border-radius: 6px;
  }
  select.input-lg {
    height: 46px;
    line-height: 46px;
  }
  textarea.input-lg,
  select[multiple].input-lg {
    height: auto;
  }
  .form-group-lg .form-control {
    height: 46px;
    padding: 10px 16px;
    font-size: 18px;
    line-height: 1.3333333;
    border-radius: 6px;
  }
  .form-group-lg select.form-control {
    height: 46px;
    line-height: 46px;
  }
  .form-group-lg textarea.form-control,
  .form-group-lg select[multiple].form-control {
    height: auto;
  }
  .form-group-lg .form-control-static {
    height: 46px;
    min-height: 38px;
    padding: 11px 16px;
    font-size: 18px;
    line-height: 1.3333333;
  }
  .has-feedback {
    position: relative;
  }
  .has-feedback .form-control {
    padding-right: 42.5px;
  }
  .form-control-feedback {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    display: block;
    width: 34px;
    height: 34px;
    line-height: 34px;
    text-align: center;
    pointer-events: none;
  }
  .input-lg + .form-control-feedback,
  .input-group-lg + .form-control-feedback,
  .form-group-lg .form-control + .form-control-feedback {
    width: 46px;
    height: 46px;
    line-height: 46px;
  }
  .input-sm + .form-control-feedback,
  .input-group-sm + .form-control-feedback,
  .form-group-sm .form-control + .form-control-feedback {
    width: 30px;
    height: 30px;
    line-height: 30px;
  }
  .has-success .help-block,
  .has-success .control-label,
  .has-success .radio,
  .has-success .checkbox,
  .has-success .radio-inline,
  .has-success .checkbox-inline,
  .has-success.radio label,
  .has-success.checkbox label,
  .has-success.radio-inline label,
  .has-success.checkbox-inline label {
    color: #3c763d;
  }
  .has-success .form-control {
    border-color: #3c763d;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  }
  .has-success .form-control:focus {
    border-color: #2b542c;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67b168;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67b168;
  }
  .has-success .input-group-addon {
    color: #3c763d;
    background-color: #dff0d8;
    border-color: #3c763d;
  }
  .has-success .form-control-feedback {
    color: #3c763d;
  }
  .has-warning .help-block,
  .has-warning .control-label,
  .has-warning .radio,
  .has-warning .checkbox,
  .has-warning .radio-inline,
  .has-warning .checkbox-inline,
  .has-warning.radio label,
  .has-warning.checkbox label,
  .has-warning.radio-inline label,
  .has-warning.checkbox-inline label {
    color: #8a6d3b;
  }
  .has-warning .form-control {
    border-color: #8a6d3b;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  }
  .has-warning .form-control:focus {
    border-color: #66512c;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #c0a16b;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #c0a16b;
  }
  .has-warning .input-group-addon {
    color: #8a6d3b;
    background-color: #fcf8e3;
    border-color: #8a6d3b;
  }
  .has-warning .form-control-feedback {
    color: #8a6d3b;
  }
  .has-error .help-block,
  .has-error .control-label,
  .has-error .radio,
  .has-error .checkbox,
  .has-error .radio-inline,
  .has-error .checkbox-inline,
  .has-error.radio label,
  .has-error.checkbox label,
  .has-error.radio-inline label,
  .has-error.checkbox-inline label {
    color: #a94442;
  }
  .has-error .form-control {
    border-color: #a94442;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  }
  .has-error .form-control:focus {
    border-color: #843534;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483;
  }
  .has-error .input-group-addon {
    color: #a94442;
    background-color: #f2dede;
    border-color: #a94442;
  }
  .has-error .form-control-feedback {
    color: #a94442;
  }
  .has-feedback label ~ .form-control-feedback {
    top: 25px;
  }
  .has-feedback label.sr-only ~ .form-control-feedback {
    top: 0;
  }
  .help-block {
    display: block;
    margin-top: 5px;
    margin-bottom: 10px;
    color: #737373;
  }
  @media (min-width: 768px) {
    .form-inline .form-group {
      display: inline-block;
      margin-bottom: 0;
      vertical-align: middle;
    }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle;
    }
    .form-inline .form-control-static {
      display: inline-block;
    }
    .form-inline .input-group {
      display: inline-table;
      vertical-align: middle;
    }
    .form-inline .input-group .input-group-addon,
    .form-inline .input-group .input-group-btn,
    .form-inline .input-group .form-control {
      width: auto;
    }
    .form-inline .input-group > .form-control {
      width: 100%;
    }
    .form-inline .control-label {
      margin-bottom: 0;
      vertical-align: middle;
    }
    .form-inline .radio,
    .form-inline .checkbox {
      display: inline-block;
      margin-top: 0;
      margin-bottom: 0;
      vertical-align: middle;
    }
    .form-inline .radio label,
    .form-inline .checkbox label {
      padding-left: 0;
    }
    .form-inline .radio input[type='radio'],
    .form-inline .checkbox input[type='checkbox'] {
      position: relative;
      margin-left: 0;
    }
    .form-inline .has-feedback .form-control-feedback {
      top: 0;
    }
  }
  .form-horizontal .radio,
  .form-horizontal .checkbox,
  .form-horizontal .radio-inline,
  .form-horizontal .checkbox-inline {
    padding-top: 7px;
    margin-top: 0;
    margin-bottom: 0;
  }
  .form-horizontal .radio,
  .form-horizontal .checkbox {
    min-height: 27px;
  }
  .form-horizontal .form-group {
    margin-right: -15px;
    margin-left: -15px;
  }
  @media (min-width: 768px) {
    .form-horizontal .control-label {
      padding-top: 7px;
      margin-bottom: 0;
      text-align: right;
    }
  }
  .form-horizontal .has-feedback .form-control-feedback {
    right: 15px;
  }
  @media (min-width: 768px) {
    .form-horizontal .form-group-lg .control-label {
      padding-top: 11px;
      font-size: 18px;
    }
  }
  @media (min-width: 768px) {
    .form-horizontal .form-group-sm .control-label {
      padding-top: 6px;
      font-size: 12px;
    }
  }

  .input-group-addon,
  .input-group-btn,
  .input-group .form-control {
    display: table-cell;
  }
  .input-group-addon:not(:first-child):not(:last-child),
  .input-group-btn:not(:first-child):not(:last-child),
  .input-group .form-control:not(:first-child):not(:last-child) {
    border-radius: 0;
  }
  .input-group-addon,
  .input-group-btn {
    width: 1%;
    white-space: nowrap;
    vertical-align: middle;
  }
  .input-group-addon {
    padding: 6px 12px;
    font-size: 14px;
    font-weight: normal;
    line-height: 1;
    color: #555;
    text-align: center;
    background-color: #eee;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  .input-group-addon.input-sm {
    padding: 5px 10px;
    font-size: 12px;
    border-radius: 3px;
  }
  .input-group-addon.input-lg {
    padding: 10px 16px;
    font-size: 18px;
    border-radius: 6px;
  }
  .input-group-addon input[type='radio'],
  .input-group-addon input[type='checkbox'] {
    margin-top: 0;
  }
  .input-group .form-control:first-child,
  .input-group-addon:first-child,
  .input-group-btn:first-child > .btn,
  .input-group-btn:first-child > .btn-group > .btn,
  .input-group-btn:first-child > .dropdown-toggle,
  .input-group-btn:last-child > .btn:not(:last-child):not(.dropdown-toggle),
  .input-group-btn:last-child > .btn-group:not(:last-child) > .btn {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .input-group-addon:first-child {
    border-right: 0;
  }
  .input-group .form-control:last-child,
  .input-group-addon:last-child,
  .input-group-btn:last-child > .btn,
  .input-group-btn:last-child > .btn-group > .btn,
  .input-group-btn:last-child > .dropdown-toggle,
  .input-group-btn:first-child > .btn:not(:first-child),
  .input-group-btn:first-child > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .input-group-addon:last-child {
    border-left: 0;
  }
  .input-group-btn {
    position: relative;
    font-size: 0;
    white-space: nowrap;
  }
  .input-group-btn > .btn {
    position: relative;
  }
  .input-group-btn > .btn + .btn {
    margin-left: -1px;
  }
  .input-group-btn > .btn:hover,
  .input-group-btn > .btn:focus,
  .input-group-btn > .btn:active {
    z-index: 2;
  }
  .input-group-btn:first-child > .btn,
  .input-group-btn:first-child > .btn-group {
    margin-right: -1px;
  }
  .input-group-btn:last-child > .btn,
  .input-group-btn:last-child > .btn-group {
    z-index: 2;
    margin-left: -1px;
  }

  .text-right {
    text-align: right;
  }

  .input-group {
    position: relative;
    display: table;
    border-collapse: separate;
  }

  .input-group .form-control {
    position: relative;
    z-index: 2;
    float: left;
    width: 100%;
    margin-bottom: 0;
  }

  .carousel-inner > .item > a > img,
  .carousel-inner > .item > img,
  .img-responsive,
  .thumbnail a > img,
  .thumbnail > img {
    display: block;
    max-width: 100%;
    height: auto;
  }

  textarea {
    resize: none;
    height: 100px !important;
  }
  paper-dialog {
    max-width: 700px;
    margin: 0 auto;
    padding: 0px;
    color: var(--app-primary-color);

    border-radius: 4px;
  }

  paper-dialog .dialog-content {
    margin: 0px;
    color: var(--app-primary-color);

    padding: 30px;
  }

  paper-dialog .dialog-header {
    margin: 0px;
    color: var(--app-primary-color);
    padding: 30px 0px 0px 0px;
  }

  paper-dialog .dialog-footer {
    margin: 0px;
    color: var(--app-primary-color);

    padding: 0px 30px 30px 30px;
  }

  paper-dialog h2 {
    margin: 0px;
    padding: 0px !important;
    margin-bottom: 10px;
  }

  paper-dialog-scrollable {
    margin-top: 20px;
    padding: 0px !important;
  }

  .text-center {
    text-align: center;
  }

  .label {
    display: inline;
    padding: 5px 10px;
    font-weight: 700;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 4px;
    font-size: 13px;
    height: 26px !important;
    line-height: 26px !important;
  }

  .label-success {
    background-color: var(--app-success-color);
  }

  .label-info {
    background-color: #5bc0de;
  }

  .label-warning {
    background-color: #f0ad4e;
  }

  .label-danger {
    background-color: #d9534f;
  }

  .label-quantity {
    background-color: #fff;
    -webkit-box-shadow: inset 0px 0px 0px 2px #d2ddec;
    -moz-box-shadow: inset 0px 0px 0px 2px #d2ddec;
    box-shadow: inset 0px 0px 0px 2px #d2ddec;
    color: var(--app-primary-color);
  }

  .label-quantity-active {
    background-color: #d2ddec;
    color: var(--app-primary-color);
  }

  .status-label {
    float: left;
    margin: 0px;
    line-height: 24px !important;
    margin-left: 5px !important;
  }

  .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
  }

  .sr-only-focusable:active,
  .sr-only-focusable:focus {
    position: static;
    width: auto;
    height: auto;
    overflow: visible;
    clip: auto;
    white-space: normal;
  }

  .justify-content-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }

  .justify-content-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }

  .justify-content-center {
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }

  .justify-content-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }

  .justify-content-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }

  .well {
    background-color: #efefef;
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 20px;
  }

  .img-thumbnail {
    padding: 4px;
    line-height: 1.42857143;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    display: inline-block;
    max-width: 100%;
    height: auto;
  }
`},QA5N:function(o,t,i){"use strict";i("Rukz").c`
  :host {
    margin: 0px;
    font-size: 13px;
    float: left;
  }

  p {
    float: left;
    font-size: 13px;
    margin: 0px;
    padding: 0px;
  }

  .label-priority {
    float: left;
    width: 15px;
    height: 15px;
    border-radius: 3px;
    margin-right: 10px;
    margin-top: 1px;
  }

  .label-priority.danger {
    background-color: #da4453;
  }

  .label-priority.warning {
    background-color: #ffc107;
  }

  .label-priority.success {
    background-color: var(--app-success-color);
  }

  .badge {
    height: 18px;
    line-height: 18px;
    color: rgb(71, 92, 123);
    border-radius: 50px;
    padding-left: 8px;
    padding-right: 8px;
  }

  .badge.btn {
    background-color: #fff;
    padding: 4px 12px 4px 12px;
    border: 0px solid #ddd;
    border-radius: 50px;
  }

  .badge.searching {
    height: 28px;
    line-height: 28px;
    color: #fff;
    border-radius: 50px;
    padding-left: 14px;
    padding-right: 14px;
    font-size: 14px;
    font-weight: 600;
    margin-right: 10px;
    position: relative;
  }

  .badge.standard {
    height: 18px;
    line-height: 18px;
    color: rgb(71, 92, 123);
    border-radius: 50px;
    background-color: rgba(71, 92, 123, 0.2) !important;
    padding-left: 8px;
    padding-right: 8px;
  }

  .badge.searching .options {
    display: none;
    position: absolute;
    top: 0px;
    width: 100%;
    z-index: 1000;
    left: 0px;
    border-radius: 50px;
    height: 28px;
    line-height: 28px;
    background-color: rgba(0, 0, 0, 0.6);
  }

  .badge.searching .options ul {
    width: 100%;
    display: flex;
    list-style: none;
    margin: 0px;
    padding: 0px;
  }

  .badge.searching .options ul li {
    flex-grow: 1;
    text-align: center;
    height: 28px;
    line-height: 28px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    color: #fff;
  }

  .badge.searching:hover > .options {
    display: inherit;
  }

  .mr-10 {
    margin-right: 10px;
  }
`},ReqB:function(o,t,i){"use strict";i.d(t,"a",(function(){return r}));const r=i("Rukz").c`
  h3 {
    color: var(--app-primary-color);
    margin: 0px;
    font-size: 15px;
    font-weight: 500;
    height: 26px;
    line-height: 26px;
  }

  h3.no-line-height {
    color: var(--app-primary-color);
    margin: 0px;
    font-size: 15px;
    font-weight: 500;
    height: auto;
    line-height: auto;
  }

  h3.bold {
    color: var(--app-primary-color);
    margin: 0px;
    font-size: 15px;
    font-weight: 800;
  }

  h3.truncate {
    width: 95%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  h3.sortable {
    cursor: pointer;
  }

  h3.small {
    color: var(--app-primary-color);
    margin: 0px;
    font-size: 12px;
    font-weight: 500;
  }

  .m-t-0 {
    margin-top: 0px;
  }

  .m-t-5 {
    margin-top: 5px;
  }

  .m-t-10 {
    margin-top: 10px;
  }

  .m-b-5 {
    margin-bottom: 5px !important;
  }

  .m-b-10 {
    margin-bottom: 10px;
  }

  i {
    color: rgba(67, 82, 99, 0.8);
  }

  fontawesome-icon {
    float: right;
    margin-right: 7px;
  }
`},RxOC:function(o,t,i){"use strict";i.d(t,"a",(function(){return r}));const r=i("Rukz").c`
  .hidden {
    display: none;
  }

  hr {
    border: 0px;
    border-bottom: 1px solid #ccc;
  }
`},"S+5Q":function(o,t,i){"use strict";i.d(t,"a",(function(){return r}));const r=i("Rukz").c`
  :host {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    align-content: stretch;
    position: relative;
    width: 36px;
    height: 36px;
    margin-right: 0px;
    color: #999;
  }

  button {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
    padding: 8px;
    box-sizing: border-box;
    background: transparent;
    border-radius: 50%;
    outline: none;
  }

  button:focus {
    background-color: #dedede;
  }

  :host([recognizing]) > button {
    background-color: #d23f31;
    fill: #fff;
    box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .ring1 {
    display: none;
    position: absolute;
    width: 200%;
    height: 200%;
    border-radius: 100%;
    background-color: rgba(0, 0, 0, 0.1);
  }

  :host([recognizing]) > .ring1 {
    display: block;
    animation: ring1-pulse 1.2s cubic-bezier(0.4, 0, 0.2, 1) infinite;
  }

  @keyframes ring1-pulse {
    0% {
      transform: scale(0.5);
    }
    40% {
      transform: scale(0.8);
    }
    100% {
      transform: scale(1);
    }
  }

  .ring2 {
    display: none;
    position: absolute;
    width: 300%;
    height: 300%;
    border-radius: 100%;
    box-sizing: border-box;
    border: 1px solid rgba(0, 0, 0, 0.08);
  }

  :host([recognizing]) > .ring2 {
    display: block;
    animation: ring2-pulse 1.5s cubic-bezier(0.4, 0, 0.2, 1) infinite;
  }

  @keyframes ring2-pulse {
    0% {
      transform: scale(0.3);
    }
    40% {
      transform: scale(0.5);
    }
    100% {
      transform: scale(1);
    }
  }
`},SSY2:function(o,t,i){"use strict";i.d(t,"a",(function(){return r}));const r=i("Rukz").c`
  .btn-barcode {
    color: #fff;
    border: 0px solid #fff;
    -webkit-transition: all 0.1s;
    -moz-transition: all 0.1s;
    -ms-transition: all 0.1s;
    transition: all 0.1s;
    margin: 0px;
    font-weight: 600;
    background-color: var(--app-primary-color);
    padding: 0px 12px !important;
    font-size: 14px;
    border-radius: 0px 4px 4px 0px;
    height: 30px;
    line-height: 30px;
    cursor: pointer;
    width: 40px;
    float: left;
  }

  six-input {
    /* display: flex; */
    /* flex-direction: column; */
    /* min-width: 100%; */
    /* max-width: 100%; */
    width: calc(100% - 40px);
    float: left;
  }
`},Tk4l:function(o,t,i){"use strict";i.d(t,"a",(function(){return r}));const r=i("Rukz").c`
  .pagination {
    width: 460px;
    margin: 0px auto;
    background-color: transparent;
    margin-top: 30px;
    position: relative;
    z-index: 0;
    text-align: center;
    font-size: 13px;
  }

  .pagination .arrow-left {
    float: left;
    width: 30px;
    position: absolute;
    height: 30px;
    text-align: center;
    line-height: 30px;
    z-index: 1000;
    left: 30px;
    cursor: pointer;
    color: var(--app-primary-color);
  }

  .pagination .arrow-left-single {
    float: left;
    width: 30px;
    position: absolute;
    height: 30px;
    text-align: center;
    line-height: 30px;
    z-index: 1000;
    left: 0px;
    cursor: pointer;
    color: var(--app-primary-color);
  }

  .pagination .arrow-left-single.disabled {
    cursor: not-allowed;
    color: #424040;
  }

  .pagination .arrow-left.disabled {
    cursor: not-allowed;
    color: #424040;
  }

  .pagination .arrow-right {
    float: left;
    width: 30px;
    position: absolute;
    height: 30px;
    text-align: center;
    line-height: 30px;
    z-index: 1000;
    right: 30px;
    top: 0px;
    cursor: pointer;
    color: var(--app-primary-color);
  }

  .pagination .arrow-right-single {
    float: left;
    width: 30px;
    position: absolute;
    height: 30px;
    text-align: center;
    line-height: 30px;
    z-index: 1000;
    right: 0px;
    top: 0px;
    cursor: pointer;
    color: var(--app-primary-color);
  }

  .pagination .arrow-right.disabled {
    cursor: not-allowed;
    color: #424040;
  }

  .pagination .arrow-right-single.disabled {
    cursor: not-allowed;
    color: #424040;
  }

  .pagination ul {
    list-style: none;
    list-style-type: none;
    margin: 0px;
    padding: 0px;
    max-width: 340px;
    white-space: nowrap;
    position: relative;
    left: 60px;
    right: 60px;
    overflow: hidden;
    overflow-x: auto;
  }

  .pagination ul li {
    text-decoration: none;
    font-size: 14px;
    color: #333;

    list-style: none;
    display: inline-block;
    width: 30px;
    height: 30px;
    line-height: 28px;
    text-align: center;
    cursor: pointer;
    transition: height width 1s ease;
    border: 2px solid #fff;
  }

  .pagination ul li a {
    font-size: 14px;
    color: #475c7b;
    border: 0px solid #ccc;
    text-decoration: none;
  }

  .pagination ul li.active {
    background-color: #fff;
    border: 2px solid #475c7b;
    border-radius: 3px;
  }

  .total-pages span {
    background-color: #475c7b;
    border-radius: 25px;
    color: #fff;
    padding: 4px 17px;
  }
`},US8t:function(o,t,i){"use strict";i.d(t,"a",(function(){return r}));const r=i("Rukz").c`
  :host {
    color: var(--app-primary-color);
  }

  .btn-default {
    color: #333;
  }
`},VEp4:function(o,t,i){"use strict";i.d(t,"a",(function(){return r}));const r=i("Rukz").c`
  six-dropdown {
    margin: 0px !important;
    padding: 0px !important;
  }
`},WXWu:function(o,t,i){"use strict";i.d(t,"a",(function(){return r}));const r=i("Rukz").c`
  .disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  .qty .count {
    color: #000;
    display: inline-block;
    vertical-align: top;
    font-size: 14px;
    line-height: 30px;
    padding: 0 3px;
    min-width: 40px;
    text-align: center;
    border: 1px solid #ccc;
    border-radius: 3px;
  }

  .qty .count.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  .qty .plus {
    cursor: pointer;
    display: inline-block;
    vertical-align: top;
    color: white;
    width: 26px;
    height: 26px;
    text-align: center;
    border-radius: 3px;
    background-color: var(--app-success-color);
  }

  .qty .plus.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  .qty .minus {
    cursor: pointer;
    display: inline-block;
    vertical-align: top;
    color: white;
    width: 26px;
    height: 26px;
    text-align: center;
    border-radius: 3px;
    background-clip: padding-box;
    background-color: var(--app-danger-color);
  }

  .qty .minus.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  .spinner-label {
    font-size: 12px;
    color: #878787;
    font-weight: normal;
    height: 22px;
    line-height: 22px;
    align-self: flex-start;
    transition: color 0.2s;
    padding-bottom: 0.5em;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    position: relative;
    max-width: 100%;
    box-sizing: border-box;
  }
`},Xi8R:function(o,t,i){"use strict";i.d(t,"a",(function(){return r}));const r=i("Rukz").c`
  .row {
    display: flex;
    align-items: baseline;
  }

  .row vaadin-text-field {
    min-width: 0;
    flex: auto;
  }

  .row .delimiter {
    padding: 0 var(--lumo-space-s);
  }

  .title {
    justify-content: space-between;
    align-items: baseline;
  }

  :host() {
    width: 100%;
  }

  vaadin-combo-box {
    width: 100%;
  }

  input[theme~='custom'] {
    color: #333 !important;
  }
`},"Y/bC":function(o,t,i){"use strict";i("Rukz").c`
  .hidden {
    display: none;
  }

  hr {
    border: 0px;
    border-bottom: 1px solid #ccc;
  }
  .commessa {
    list-style: none;
    background-color: #fff;
    margin-bottom: 0px;
    border-radius: 0px;
    height: 54px;
    color: var(--app-primary-color);
    -webkit-transition: color 300ms, background-color 300ms, box-shadow 700ms;
    -moz-transition: color 300ms, background-color 300ms, box-shadow 700ms;
    -o-transition: color 300ms, background-color 300ms, box-shadow 700ms;
    transition: color 300ms, background-color 300ms, box-shadow 700ms;
    position: relative;
    border-bottom: 1px dotted rgba(67, 82, 99, 0.3) !important;
  }

  .commessa .icon {
    background-color: var(--app-primary-color);
    cursor: pointer;
    height: 54px;
    line-height: 54px;
    width: 54px;
    border-radius: 0px;
    text-align: center;
    color: #fff;
    position: absolute;
    left: 0px;
    -webkit-transition: color 300ms, background-color 200ms;
    -moz-transition: color 300ms, background-color 200ms;
    -o-transition: color 300ms, background-color 200ms;
    transition: color 300ms, background-color 200ms;
    border-bottom: 1px solid #fff;
  }

  .commessa .icon.shipment {
    cursor: pointer;
    height: 54px;
    line-height: 54px;
    width: 54px;
    border-radius: 0px;
    text-align: center;
    color: var(--app-primary-color);
    position: absolute;
    left: 0px;
    -webkit-transition: color 300ms, background-color 200ms;
    -moz-transition: color 300ms, background-color 200ms;
    -o-transition: color 300ms, background-color 200ms;
    transition: color 300ms, background-color 200ms;
    border-bottom: 1px solid #fff;
  }

  .commessa.active {
    background-color: rgba(67, 82, 99, 0.1);
    list-style: none;
    margin-bottom: 0px;
    border-radius: 0px;
    height: 54px;
  }

  .commessa.active .icon {
    background-color: var(--app-selected-color);
    list-style: none;
    cursor: pointer;
    margin-bottom: 10px;
    border-radius: 0px;
    float: left;
    height: 54px;
  }

  .commessa .icon:hover {
    background-color: #4fc1e9;
  }

  .commessa .content {
    padding: 7px 20px;

    color: var(--app-primary-color);
    position: absolute;
    left: 54px;
    right: 54px;
    width: calc(100% - 53px);
  }

  .commessa .content .title {
    padding: 0px;
    float: left;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
  }

  .commessa .content .title h3 {
    margin: 0px;
    padding: 0px;
    font-size: 15px;
    white-space: nowrap;
    overflow: hidden;
    width: calc(100% - 40px);
  }

  .commessa .content .description {
    margin: 0px;
    padding: 0px;
    font-size: 12px;
    float: left;
    width: calc(100% - 40px);
  }

  .commessa .content .description.hidden six-badge {
    display: none;
  }

  .commessa .content .description p {
    float: left;
    margin: 0px;
    padding: 0px;
    margin-left: 0px;
  }

  .commessa .content .description p.spaced {
    margin-right: 10px !important;
  }

  .commessa .button {
    position: absolute;
    right: 0px;
    top: 0px;
    height: 54px;
    text-align: center;
    line-height: 70px;
    width: 54px;
  }

  .selected-kit {
    background-color: red;
  }
  .selected-dest {
    background-color: yellow;
  }

  .with-background {
    background-color: #d1dae7;
  }
`},YJgx:function(o,t,i){"use strict";i.d(t,"a",(function(){return r}));const r=i("Rukz").c`
  .legenda-item.horizontal {
    background-color: #fff;
    list-style: none;
    color: var(--app-primary-color);
    -webkit-transition: color 300ms, background-color 300ms, box-shadow 700ms;
    -moz-transition: color 300ms, background-color 300ms, box-shadow 700ms;
    -o-transition: color 300ms, background-color 300ms, box-shadow 700ms;
    transition: color 300ms, background-color 300ms, box-shadow 700ms;
    padding: 0px 8px 0px 0px;
    margin: 0px;
    font-size: 13px;
    float: left;
    border-right: 0px dotted var(--app-primary-color);
    margin-right: 6px;
    height: 32px;
  }

  .legenda-item.horizontal .icon {
    float: left;
    margin-right: 4px;
  }

  .legenda-item.vertical {
    background-color: #fff;
    list-style: none;
    color: var(--app-primary-color);
    -webkit-transition: color 300ms, background-color 300ms, box-shadow 700ms;
    -moz-transition: color 300ms, background-color 300ms, box-shadow 700ms;
    -o-transition: color 300ms, background-color 300ms, box-shadow 700ms;
    transition: color 300ms, background-color 300ms, box-shadow 700ms;
    padding: 0px 8px 0px 0px;
    margin: 0px;
    font-size: 13px;
    float: left;
    width: 100%;
    border-right: 0px dotted var(--app-primary-color);
    margin-right: 6px;
    height: 20px;
  }

  .legenda-item.vertical .icon {
    float: left;
    margin-right: 4px;
    width: 26px;
  }
`},YaIN:function(o,t,i){"use strict";i.d(t,"a",(function(){return r}));const r=i("Rukz").c`
  :host {
    color: var(--app-primary-color);
  }

  iron-overlay-backdrop:host(.opened) {
    opacity: var(--iron-overlay-backdrop-opacity, 0.6);
    pointer-events: auto;
    background-color: var(--iron-overlay-backdrop-background-color, #222) !important;
  }

  .mt-30 {
    margin-top: 30px !important;
  }

  .box {
    display: -webkit-box;
  }
`},aFSb:function(o,t,i){"use strict";i.d(t,"a",(function(){return r}));const r=i("Rukz").c`
  h3 {
    margin: 0px;
    color: var(--app-primary-color);
    margin-bottom: 0px;
    height: 30px;
    line-height: 30px;
    font-size: 15px;
  }

  h3.topbar-title {
    width: 95%;
    min-width: 100px;
    max-width: 300px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  h3.no-line-height {
    color: var(--app-primary-color);
    margin: 0px;
    font-size: 15px;
    font-weight: 500;
    height: auto;
    line-height: auto !important;
  }

  i {
    color: rgba(67, 82, 99, 0.4);
  }

  h3.topbar-title.blue {
    color: #253655;
  }

  .m-t-0 {
    margin-top: 0px;
  }

  .m-b-5 {
    margin-bottom: 5px !important;
  }

  .m-b-10 {
    margin-bottom: 10px;
  }

  fontawesome-icon {
    float: left;
    margin-right: 7px;
  }
`},b1YX:function(o,t,i){"use strict";i.d(t,"a",(function(){return r}));const r=i("Rukz").c`
  six-button {
    padding: 0px;
    margin: 0px;
  }

  iron-overlay-backdrop:host(.opened) {
    opacity: var(--iron-overlay-backdrop-opacity, 0.6);
    pointer-events: auto;
    background-color: var(--iron-overlay-backdrop-background-color, #222) !important;
  }

  paper-dialog {
    max-width: 500px;
  }

  paper-dialog .dialog-footer {
    padding: 0px !important;
  }

  .nav-slides {
    overflow: hidden;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .nav-list {
    list-style: none;
    white-space: nowrap;
    padding: 0px;
    padding-left: 0;
    font-size: 16px;
    margin-bottom: 0px;
  }

  .nav-list li {
    display: inline-block;
    margin-right: 7px;
    position: relative;
  }

  .image-container img {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    object-fit: contain;
    object-position: center center;
  }

  .nav-list li img {
    width: 80px;
  }

  .image-container {
    height: 300px;
    background-color: #efefef;
  }

  video {
    width: 100%;
    object-fit: contain;
    object-position: center center;
  }

  canvas {
    width: 100%;
    object-fit: contain;
    object-position: center center;
  }

  .blue-area {
    background-color: #efefef;
    height: 100%;
  }
`},b7Ej:function(o,t,i){"use strict";i.d(t,"a",(function(){return r}));const r=i("Rukz").c`
  .hidden {
    display: none;
  }
`},bhAH:function(o,t,i){"use strict";i.d(t,"a",(function(){return r}));const r=i("Rukz").c`
  .accettazione {
    list-style: none;
    background-color: #fff;
    margin-bottom: 0px;
    border-radius: 0px;
    height: 54px;
    color: var(--app-primary-color);
    -webkit-transition: color 300ms, background-color 300ms, box-shadow 700ms;
    -moz-transition: color 300ms, background-color 300ms, box-shadow 700ms;
    -o-transition: color 300ms, background-color 300ms, box-shadow 700ms;
    transition: color 300ms, background-color 300ms, box-shadow 700ms;
    position: relative;
    border-bottom: 1px solid rgba(67, 82, 99, 0.3);
  }

  .accettazione:hover {
    background-color: rgba(67, 82, 99, 0.1);
    transition: transform 0.4s ease-out, -webkit-transform 0.4s ease-out;
  }

  .accettazione .icon {
    background-color: var(--app-primary-color);
    border-bottom: 0px solid #ccc;
    cursor: pointer;
    height: 54px;
    line-height: 54px;
    width: 54px;
    border-radius: 0px;
    text-align: center;
    color: #fff;
    position: absolute;
    left: 0px;
  }

  .accettazione.active {
    background-color: rgba(67, 82, 99, 0.1);

    list-style: none;
    margin-bottom: 0px;
    border-radius: 0px;
    height: 54px;
  }

  .accettazione.active .icon {
    background-color: var(--app-selected-color);
    list-style: none;
    cursor: pointer;
    margin-bottom: 10px;
    border-radius: 0px;
    float: left;
    height: 54px;
  }

  .accettazione .content {
    padding: 7px 20px;

    color: var(--app-primary-color);
    cursor: pointer;
    position: absolute;
    left: 54px;
    right: 54px;
    width: calc(100% - 100px);
  }

  .accettazione .content .title {
    padding: 0px;
    float: left;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
  }

  .accettazione .content .title h3 {
    margin: 0px;
    padding: 0px;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    width: calc(100% - 40px);
  }

  .accettazione .content .description {
    margin: 0px;
    padding: 0px;
    font-size: 12px;
    float: left;
    width: calc(100% - 40px);
  }

  .accettazione .content .description.hidden six-badge {
    display: none;
  }

  .accettazione .content .description p {
    float: left;
    margin: 0px;
    padding: 0px;
    margin-left: 0px;
  }

  .accettazione .button {
    position: absolute;
    right: 0px;
    top: 0px;
    height: 54px;
    text-align: center;
    line-height: 70px;
    width: 54px;
  }
`},d6rb:function(o,t,i){"use strict";i("Rukz").c`
  .row {
    display: flex;
    align-items: baseline;
  }

  .row vaadin-text-field {
    min-width: 0;
    flex: auto;
  }

  .row .delimiter {
    padding: 0 var(--lumo-space-s);
  }

  .title {
    justify-content: space-between;
    align-items: baseline;
  }

  :host() {
    width: 100%;
  }

  vaadin-combo-box {
    width: 100%;
  }

  input[theme~='custom'] {
    color: #333 !important;
  }
  .label {
    font-size: 12px;
    color: #878787 !important;
    font-weight: normal;
    height: 22px;
    line-height: 22px;
  }
`},dYHS:function(o,t,i){"use strict";i.d(t,"a",(function(){return r}));const r=i("Rukz").c`
  .label {
    font-size: 12px;
    color: #878787 !important;
    font-weight: normal;
    height: 22px;
    line-height: 22px;
  }

  vaadin-text-area {
    width: 100%;
    min-height: 140px;
    padding: 0px !important;
    padding-top: 0px !important;
    margin: 0px !important;
  }

  textarea {
    line-height: 20px !important;
  }

  input[theme~='custom'] {
    color: #333 !important;
    font-size: 13px;
    line-height: 18px;
  }
`},ddCf:function(o,t,i){"use strict";i.d(t,"a",(function(){return r}));const r=i("Rukz").c`
  :host {
    background: #fff;
  }
  #sidebarfilter {
    position: fixed;
    max-width: 50%;
    top: 0px;
    right: 0px;
    height: 100vh;
    background: #fff;
    color: #333;
    transition: all 0.3s;
    z-index: 100;
    border-left: 0px solid #efefef;

    box-shadow: 0px 1px 7px rgba(61, 86, 114, 0.2);
  }

  #sidebarfilter .content {
    padding: 0px 20px;
  }

  #sidebarfilter form {
    padding: 20px 0px;
  }

  #sidebarfilter label {
    font-size: 14px;
    color: #999999;
  }

  #sidebarfilter form label {
    font-size: 13px;
    font-weight: 300;
  }
  #sidebarfilter form .form-group {
    margin-bottom: 6px;
  }
  #sidebarfilter.active {
    right: -50%;
    position: fixed;
    z-index: 100;
  }
  #sidebarfilter .sidebar-header {
    padding: 12px 15px 13px 15px;
    font-size: 24px;
  }
  #sidebarfilter .sidebar-header h3 {
    margin-top: 0px;
    margin-bottom: 0px;
    color: #fff;
    font-size: 16px;
    padding-top: 7px;
    width: 350px;
  }

  #sidebarfilter ul.components {
    padding: 20px 0;
    border-bottom: 1px solid #47748b;
  }
  #sidebarfilter ul.components li a {
    padding: 10px;
    font-size: 1.1em;
    display: block;
  }
  #sidebarfilter ul.components li a:hover {
    color: #7386d5;
    background: #fff;
  }
  #sidebarfilter ul.components p {
    color: #fff;
    padding: 10px;
  }

  #overlay.active {
    opacity: 0;
    display: none;
    -webkit-transition: display 2s, opacity 2s, -webkit-transform 2s !important; /* Safari */
    transition: display 2s, opacity 2s, transform 2s !important;
  }

  #overlay.hidden {
    opacity: 0;
    display: none;
    visibility: hidden;
  }

  #overlay {
    position: fixed;
    width: 100%;
    display: inherit;
    height: 100%;
    background: #333;
    z-index: 100;
    opacity: 0.5;
    top: 0px;
    left: 0px;
  }

  @media screen and (max-width: 520px) {
    #overlay {
      position: fixed;
      width: 100%;
      display: inherit;
      height: 100%;
      background: #333;
      z-index: 100;
      opacity: 0.5;
      top: 0px;
      left: 0px;
    }
  }

  #sidebarfilter{
    max-width: 50% !important;
    min-width: 50%;
  }

  #sidebarfilter.xs {
    max-width: 25% !important;
    min-width: 25%;
  }
  #sidebarfilter.active.xs {
    right: -25% !important;
  }
  #sidebarfilter.sm {
    max-width: 40% !important;
    min-width: 40%;
  }
  #sidebarfilter.active.sm {
    right: -40% !important;
  }
  #sidebarfilter.md {
    max-width: 65% !important;
    min-width: 65%;
  }
  #sidebarfilter.active.md {
    right: -65% !important;
  }
  #sidebarfilter.lg {
    max-width: 80% !important;
    min-width: 80%;
  }
  #sidebarfilter.active.lg {
    right: -85% !important;
  }
  #sidebarfilter.xl {
    max-width: 90% !important;
    min-width: 30%;
  }
  #sidebarfilter.active.xl {
    right: -95% !important;
  }

  #sidebarfilter.full {
    max-width: 100% !important;
    min-width: 100%;
    background-color: #ebeeee;
  }

  #sidebarfilter.full form {
    margin: 0px;
    padding: 0px;
  }

  #sidebarfilter.active.full {
    right: -105% !important;
  }

  .sidebar-body {
    overflow-y: auto;
    height: -webkit-fill-available;
    padding-bottom: 60px;
  }
`},euAh:function(o,t,i){"use strict";i.d(t,"a",(function(){return r}));const r=i("Rukz").c`
  :host {
    display: block;
    position: fixed;
    top: 100%;
    left: 0;
    right: 0;
    padding: 12px;
    background-color: var(--app-secondary-color);
    color: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    text-align: center;
    will-change: transform;
    transform: translate3d(0, 0, 0);
    transition-property: visibility, transform;
    transition-duration: 0.2s;
    visibility: hidden;
  }

  :host([active]) {
    visibility: visible;
    transform: translate3d(0, -100%, 0);
  }

  @media (min-width: 460px) {
    :host {
      width: 320px;
      margin: auto;
    }
  }
`},gDGF:function(o,t,i){"use strict";i.d(t,"a",(function(){return r}));const r=i("Rukz").c`
  vaadin-date-picker {
    width: 100%;
  }
`},iXTq:function(o,t,i){"use strict";i.d(t,"a",(function(){return r}));const r=i("Rukz").c`
  :host {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0px 0px;
    box-sizing: border-box;
    border-radius: 2px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08);
    background-color: rgba(255, 255, 255, 0.9);
    color: #697689;
  }

  .icon {
    display: inline-block;
    width: 28px;
    height: 24px;
    padding: 0px;
    box-sizing: border-box;
    opacity: 0.7;
    padding-left: 6px;
    line-height: 30px;
  }

  .decorator {
    flex: 1;
    position: relative;
    margin: 0px;
    border-bottom: 0px solid #bbb;
    color: #697689;
  }

  .decorator > ::slotted(input) {
    font-size: 16px;
    font-weight: 400;
    border: none;
    width: 100%;
    padding: 0 0 1px 0;
    background: transparent;
    font-family: inherit;
    outline: none;
    -webkit-appearance: none;
    color: #697689;
  }

  .underline {
    display: block;
    height: 0px;
    width: 100%;
    background-color: var(--app-secondary-color, navy);
    position: absolute;
    bottom: 0px;
    left: 0;
    will-change: transform;
    transform: scale3d(0, 1, 1);
    transition: transform 0.2s ease-in;
  }

  [focused] > .underline {
    transform: scale3d(1, 1, 1);
    transition: transform 0.2s ease-out;
  }
`},lAwY:function(o,t,i){"use strict";i.d(t,"a",(function(){return r}));const r=i("Rukz").c`
  label {
    margin: 0px 0px 5px 0px;
    font-size: 12px;
    color: #878787 !important;
    font-weight: normal;
    height: 22px;
    line-height: 22px;
    padding-bottom: 0.5em;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    position: relative;
    max-width: 100%;
    box-sizing: border-box;
    font-family: Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  }
`},"m/xq":function(o,t,i){"use strict";i.d(t,"a",(function(){return r}));const r=i("Rukz").c`
  :host {
    display: block;

    --app-drawer-width: 356px;

    --app-primary-color: #475c7b;
    --app-secondary-color: #394b6a;
    --app-third-color: #3c597c;
    --app-fourth-color: var(--lumo-primary-color-10pct) !important;
    --app-dark-text-color: var(--app-secondary-color);
    --app-light-text-color: white;
    --app-section-even-color: #f7f7f7;
    --app-section-odd-color: white;
    --app-danger-color: #ed5565;
    --app-success-color: #5cb85c;
    --app-warning-color: #e8dc58;
    --app-warning-color-2: #ff9f00;
    --app-selected-color: #4fc1e9;
    --app-danger-background-color: #ff9faa;
    --app-success-background-color: #b3e590;
    --app-warning-background-color: #fff266;
    --app-warning2-background-color: #ff9f00;
    --app-selected-background-color: #d9edf7;

    --app-success-color-light: rgba(140, 193, 82, 0.5);

    --app-header-background-color: white;
    --app-header-text-color: var(--app-dark-text-color);
    --app-header-selected-color: var(--app-primary-color);

    --app-drawer-background-color: var(--app-secondary-color);
    --app-drawer-text-color: var(--app-light-text-color);
    --app-drawer-selected-color: #78909c;

    --lumo-primary-text-color: #1c2b48;
    --lumo-secondary-color: #293237;
  }

  app-header {
    top: 0;
    left: 0;
    width: 100%;
    text-align: center;
    background-color: var(--app-header-background-color);
    color: var(--app-header-text-color);
    border-bottom: 0px solid #eee;
    z-index: 99;
  }

  vaadin-tab {
    color: #fff;
    font-size: 14px;
  }

  vaadin-tab span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  vaadin-tab iron-icon {
    width: 40px;
    margin-right: 5px;
  }

  vaadin-tab.tabs::-moz-selection {
    color: red;
  }

  .toolbar-top {
    height: 54px;
    background-color: transparent;
    box-shadow: none;
  }

  .toolbar-offline {
    background-color: rgb(190, 229, 235);
  }

  .toolbar-top > a {
    display: inline-block;
    color: var(--app-primary-color);
    text-decoration: none;
    line-height: 30px;
    padding: 5px 15px 5px 0px;
    cursor: pointer;
    font-size: 20px;
  }

  .toolbar-top > .workstation {
    display: inline-block;
    color: var(--app-primary-color);
    text-decoration: none;
    line-height: 30px;
    padding: 5px 15px 5px 0px;
    cursor: pointer;
    font-size: 25px;
  }

  .toolbar-top > .user {
    display: inline-block;
    color: var(--app-primary-color);
    text-decoration: none;
    line-height: 30px;
    padding: 5px 15px 5px 0px;
    cursor: pointer;
    font-size: 25px;
  }

  .toolbar-top-logo {
    max-height: 64px;
    margin-top: 0px;
    padding: 0px;
    margin-left: 0px;
    border-right: 0px solid #ccc;
    border-left: 0px solid #ccc;
    margin-right: 0px;
  }

  .toolbar-top-logo img {
    margin-top:  0px;
    padding-right: 13px;
    padding-left: 0px;
    max-height: 40px;
    height: 28px;
  }

  .toolbar-top > .messages {
    display: inline-block;
    color: var(--app-primary-color);
    text-decoration: none;
    line-height: 30px;
    padding: 5px 15px 5px 0px;
    cursor: pointer;
    font-size: 25px;
  }

  [main-title] {
    font-family: 'Roboto';
    text-transform: uppercase;
    font-size: 30px;
    /* In the narrow layout, the toolbar is offset by the width of the
  drawer button, and the text looks not centered. Add a padding to
  match that button */
    padding-right: 44px;
  }

  .toolbar-list {
    display: none;
    background-color: #475c7b;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 3px;
    position: relative;
    z-index: 100;
  }

  .toolbar-list > a {
    display: inline-block;
    color: var(--app-header-text-color);
    text-decoration: none;
    line-height: 30px;
    padding: 4px 24px;
  }

  .toolbar-list > a[selected] {
    color: var(--app-header-selected-color);
    border-bottom: 4px solid var(--app-header-selected-color);
  }

  .menu-btn {
    background: none;
    border: none;
    fill: var(--app-header-text-color);
    cursor: pointer;
    height: 24px;
    width: 24px;
  }

  app-drawer {
    z-index: 200000;
  }

  .drawer-list {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding: 20px;
    background: var(--app-drawer-background-color);
    position: relative;
  }

  .drawer-list > a {
    display: block;
    text-decoration: none;
    color: var(--app-drawer-text-color);
    padding: 0px 10px;
    font-size: 15px;
    height: 50px;
    line-height: 50px;
    border-bottom: 1px dotted #ccc;
    -webkit-transition: color 300ms, background-color 300ms;
    -moz-transition: color 300ms, background-color 300ms;
    -o-transition: color 300ms, background-color 300ms;
    transition: color 300ms, background-color 300ms;
  }

  .drawer-list > a span.text {
    margin-left: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    float: left;
    width: calc(100% - 40px);
  }

  .drawer-list > a span.icon {
    float: left;
    width: 30px;
    margin-left: 0px;
  }

  .drawer-list > a[selected] {
    color: #fff;
    background: var(--app-drawer-selected-color);
  }

  .drawer-list > a:hover {
    color: #fff;
    background: var(--app-drawer-selected-color);
  }

  .dropdown {
    position: relative;
    display: inline-block;
    border-right: 1px solid #ccc;
    padding-right: 15px;
  }

  .dropdown-content {
    display: none;
    position: absolute;
    min-width: 160px;
    padding: 0px;
    z-index: 1;
    right: 0px;
    top: 30px;
    border: 0px solid #ccc;
  }

  .dropdown-content ul {
    padding: 0px;
    margin: 0px;
    background-color: #475c7b;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    border-radius: 0px 0px 3px 3px;
    list-style-type: none;
    position: relative;
    top: 10px;
  }

  .dropdown-content ul li {
    padding: 2px 10px;
    margin: 0px;
    list-style: none;
    text-align: left;
    border-bottom: 1px solid #fff;
    font-size: 13px;
    width: calc(100% - 20px);
    -webkit-transition: color 300ms, background-color 300ms;
    -moz-transition: color 300ms, background-color 300ms;
    -o-transition: color 300ms, background-color 300ms;
    transition: color 300ms, background-color 300ms;
  }

  .dropdown-content ul li:last-child {
    border-bottom: 0px solid #fff;
  }

  .dropdown-content ul li a,
  div {
    padding: 0px;
    margin: 0px;
    text-decoration: none;
    color: #fff;
  }

  .dropdown-content ul li:hover {
    background-color: #ebeeee;
    color: #475c7b !important;
  }

  .dropdown-content ul li:hover a {
    color: #475c7b !important;
  }

  .dropdown-content ul li a:hover {
    color: #475c7b !important;
  }

  .dropdown:hover .dropdown-content {
    display: block;
  }

  .dropdown-messages {
    position: relative;
    display: inline-block;
    border-left: 0px solid #ccc;
    border-right: 1px solid #ccc;
    padding-left: 10px;
  }

  .dropdown-messages .alert-messages {
    background-color: #e6155a;
    position: absolute;
    margin: 0px;
    font-size: 12px;
    padding: 0px;
    color: #fff;
    border-radius: 25px;
    right: 8px;
    top: 3px;
    height: 10px;
    width: 10px;
  }

  .dropdown-content-messages {
    display: none;
    position: absolute;
    min-width: 300px;
    padding: 0px;
    z-index: 1;
    right: 0px;
    top: 30px;
    border: 0px solid #ccc;
  }

  .dropdown-content-messages h4 {
    font-size: 14px;
    padding: 0px;
    color: #5bc0de;
    margin: 0px;
    line-height: 120%;
    margin-bottom: 4px;
  }

  .dropdown-content-messages p {
    padding: 0px;
    font-size: 13px;
    color: #475c7b;
    margin: 0px;
    line-height: 120%;
  }

  .dropdown-content-messages ul {
    padding: 0px;
    margin: 0px;
    background-color: #fff;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    border-radius: 0px 0px 3px 3px;
    list-style-type: none;
    position: relative;
    top: 10px;
    max-height: 170px;
    overflow: hidden;
    overflow-y: scroll;
    border-top: 2px solid #78909c;
  }

  .dropdown-content-messages ul li {
    margin: 0px;
    list-style: none;
    text-align: left;
    border-bottom: 1px solid #ccc;
    font-size: 13px;
    padding: 8px 10px;
    width: calc(100% - 20px);
    -webkit-transition: color 300ms, background-color 300ms;
    -moz-transition: color 300ms, background-color 300ms;
    -o-transition: color 300ms, background-color 300ms;
    transition: color 300ms, background-color 300ms;
  }

  .dropdown-content-messages ul li:last-child {
    border-bottom: 0px solid #fff;
  }

  .dropdown-content-messages ul li a {
    padding: 0px;
    margin: 0px;
    text-decoration: none;
    color: #475c7b;
  }

  .dropdown-content-messages ul li:hover {
    background-color: #ebeeee;
    color: #475c7b !important;
  }

  .dropdown-content-messages ul li:hover a {
    color: #475c7b !important;
  }

  .dropdown-content-messages ul li a:hover {
    color: #475c7b !important;
  }

  .dropdown-messages:hover .dropdown-content-messages {
    display: block;
  }

  .dropdown-bookmarks {
    position: relative;
    display: inline-block;
    border-left: 0px solid #ccc;
    border-right: 0px solid #ccc;
    padding-left: 0px;
  }

  .dropdown-bookmarks .alert-bookmarks {
    background-color: #e6155a;
    position: absolute;
    margin: 0px;
    font-size: 12px;
    padding: 0px;
    color: #fff;
    border-radius: 25px;
    right: 8px;
    top: 3px;
    height: 10px;
    width: 10px;
  }

  .dropdown-content-bookmarks {
    display: none;
    position: absolute;
    min-width: 300px;
    padding: 0px;
    z-index: 1;
    right: 0px;
    top: 30px;
    border: 0px solid #ccc;
  }

  .dropdown-content-bookmarks h4 {
    font-size: 14px;
    padding: 0px;
    color: #475c7b;
    margin: 0px;
    line-height: 120%;
    margin-bottom: 4px;
  }

  .dropdown-content-bookmarks p {
    padding: 0px;
    font-size: 13px;
    color: #475c7b;
    margin: 0px;
    line-height: 120%;
  }

  .dropdown-content-bookmarks ul {
    padding: 0px;
    margin: 0px;
    background-color: #fff;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    border-radius: 0px 0px 3px 3px;
    list-style-type: none;
    position: relative;
    top: 10px;
    height: 100%;
    max-height: 400px;
    overflow: hidden;
    overflow-y: scroll;
    border-top: 2px solid #78909c;
  }

  .dropdown-content-bookmarks ul li {
    margin: 0px;
    list-style: none;
    text-align: left;
    font-size: 13px;
    padding: 0px;
    width: 50%;
    height: 100px;
    float: left;
    -webkit-transition: color 300ms, background-color 300ms;
    -moz-transition: color 300ms, background-color 300ms;
    -o-transition: color 300ms, background-color 300ms;
    transition: color 300ms, background-color 300ms;
  }

  .dropdown-content-bookmarks iron-icon {
    color: #4fc1e9;
    margin-bottom: 10px;
  }

  .dropdown-content-bookmarks ul li .inner-content {
    padding: 20px;
    text-align: center;
    color: #475c7b;
  }

  .dropdown-content-bookmarks ul li .inner-content i {
    font-size: 20px;
  }

  .dropdown-content-bookmarks ul li a {
    padding: 0px;
    margin: 0px;
    text-decoration: none;
    color: #475c7b;
  }

  .dropdown-content-bookmarks ul li:hover {
    background-color: #ebeeee;
    color: #475c7b !important;
  }

  .dropdown-content-bookmarks ul li:hover a {
    color: #475c7b !important;
  }

  .dropdown-content-bookmarks ul li a:hover {
    color: #475c7b !important;
  }

  .dropdown-bookmarks:hover .dropdown-content-bookmarks {
    display: block;
  }

  /* .main-page-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 0;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -moz-transition: all 300ms;
    -webkit-transition: all 300ms;
    transition: all 300ms;
  } */

  .shift {
    padding-left: 376px;
  }

  /* Workaround for IE11 displaying <main> as inline */
  main {
    display: block;
  }

  .main-content {
    padding-top: 0px !important;
    min-height: auto;
  }

  .page {
    display: none;
  }

  .page[active] {
    display: block;
  }

  footer {
    padding: 24px;
    background: var(--app-drawer-background-color);
    color: var(--app-drawer-text-color);
    text-align: center;
  }

  search-input-decorator {
    max-width: 460px;
    transform: translate3d(0, 374px, 0);
  }

  search-input-decorator[top] {
    transition: transform 250ms cubic-bezier(0.4, 0, 0.2, 1);
    transform: translate3d(0, 0, 0);
    box-shadow: none;
    border-radius: 25px;
    border: 1px solid #ccc !important;
    width: 100%;
    margin: 0 auto;
    height: 36px;
  }

  six-code-reader[top] {
    max-width: 460px;
    width: 100%;
    margin: 0 auto;
    height: 36px;
  }

  .no-margin {
    padding: 0px;
    margin: 0px;
  }

  /* Wide layout: when the viewport width is bigger than 460px, layout
changes to a wide layout */
  @media (min-width: 460px) {
    .toolbar-list {
      display: block;
    }

    /* The drawer button isn't shown in the wide layout, so we don't
  need to offset the title */
    [main-title] {
      padding-right: 0px;
    }

    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }
  }
`},nM18:function(o,t,i){"use strict";i.d(t,"a",(function(){return r}));const r=i("Rukz").c`
  .hidden {
    display: none;
  }

  hr {
    border: 0px;
    border-bottom: 1px solid #ccc;
  }
`},oX9d:function(o,t,i){"use strict";i("Rukz").c`
  vaadin-combo-box {
    width: 100%;
  }

  input[theme~='custom'] {
    color: #333 !important;
  }
`},"p+EW":function(o,t,i){"use strict";i("Rukz").c`
  :host {
    margin: 0px;
  }

  .alert-light {
    color: #818182;
    background-color: #fefefe;
    border-color: #fdfdfe;
  }

  .alert-danger {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
  }

  .alert-info {
    color: #0c5460;
    background-color: #d1ecf1;
    border-color: #bee5eb;
  }

  .alert-warning {
    color: #856404;
    background-color: #fff3cd;
    border-color: #ffeeba;
  }

  .alert-success {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
  }

  .mt-5 {
    margin-top: 5px !important;
  }

  .mt-10 {
    margin-top: 10px !important;
  }

  .mt-15 {
    margin-top: 15px !important;
  }

  .mt-20 {
    margin-top: 20px !important;
  }

  .mt-25 {
    margin-top: 25px !important;
  }
`},r9Mb:function(o,t,i){"use strict";i("Rukz").c`
  .priority-legend-margin {
    margin: 0px 10px 0px 10px;
  }
`},tGes:function(o,t,i){"use strict";i("Rukz").c`
  .btn-back {
    color: #999;
    font-weight: 600;
    border-radius: 4px;
    border-width: 0px;
    border-style: solid;
    border-color: rgb(255, 255, 255);
    border-image: initial;
    transition: all 0.1s ease 0s;
    background-color: #fff;
    border: 1px solid #ccc;
    height: 28px !important;
    font-size: 12px;
    text-decoration: none;
    line-height: 28px;
    text-align: center;
    padding: 0px 3px;
  }

  .btn-back:hover {
    background-color: rgb(71, 92, 123);
    color: #fff;
  }
`},thEk:function(o,t,i){"use strict";i.d(t,"a",(function(){return r}));const r=i("Rukz").c`
  .elements-item {
    background-color: #fff;
    border-bottom: 1px solid #ccc;
    list-style: none;
    color: var(--app-primary-color);
    -webkit-transition: color 300ms, background-color 300ms, box-shadow 700ms;
    -moz-transition: color 300ms, background-color 300ms, box-shadow 700ms;
    -o-transition: color 300ms, background-color 300ms, box-shadow 700ms;
    transition: color 300ms, background-color 300ms, box-shadow 700ms;
    padding: 0px 0px 0px 0px;
    margin: 0px;
    padding-top: 6px;
    min-height: 34px;
    line-height: auto;
    padding-right: 10px;
    padding-left: 10px;
  }

  .elements-item.active {
    background-color: #d1ecf1 !important;
    transition: transform 0.4s ease-out, -webkit-transform 0.4s ease-out;
    border-bottom: 1px solid #fff !important;
  }

  .elements-item.active-success {
    background-color: #d4edda !important;
    transition: transform 0.4s ease-out, -webkit-transform 0.4s ease-out;
    border-bottom: 1px solid #fff !important;
  }

  .elements-item.lampeggiante {
    transition: transform 0.2s ease-out, -webkit-transform 0.2s ease-out;
    animation: pulse 1s infinite ease-in-out;
  }

  .elements-item.cancelled {
    background-color: #f8d7da;
    transition: transform 0.2s ease-out, -webkit-transform 0.2s ease-out;
    border-bottom: 1px solid #fff !important;
  }

  .elements-item.blocked {
    background-color: #fff3cd;
    transition: transform 0.4s ease-out, -webkit-transform 0.4s ease-out;
  }

  @keyframes pulse {
    0% {
      background-color: #fff;
    }
    50% {
      background-color: rgba(255, 206, 84, 0);
    }
    100% {
      background-color: #fff;
    }
  }
`},u2Uk:function(o,t,i){"use strict";i.d(t,"a",(function(){return r}));const r=i("Rukz").c`
  .cart {
    background-color: #ffffff;
    border-bottom: 0px solid #ccc;
    list-style: none;
    margin-bottom: 30px;
    border-radius: 4px;
    height: 100px;
    color: var(--app-primary-color);
    -webkit-transition: color 300ms, background-color 300ms, box-shadow 700ms;
    -moz-transition: color 300ms, background-color 300ms, box-shadow 700ms;
    -o-transition: color 300ms, background-color 300ms, box-shadow 700ms;
    transition: color 300ms, background-color 300ms, box-shadow 700ms;
  }



  .cart .content {
    color: var(--app-primary-color);
    width: calc(100% - 100px);
    margin-left: 100px;
  }
  .cart .content .inner {
    padding: 20px 20px;
  }
  .cart .content.busy .inner {
    padding: 14px 20px;
  }
  .cart .content h3.title {
    margin: 0px;
    padding: 0px;
    font-size: 20px;
  }
  .cart .content p.description {
    margin: 0px;
    padding: 0px;
    font-size: 14px;
    font-weight: bold;
  }
  .cart .content p.description.busy {
    font-weight: bold;
  }
  .cart .content p.subdescription {
    margin: 0px;
    padding: 0px;
    font-size: 12px;
  }

  .progress-wrapper {
    margin-bottom: 5px;
    margin-top: 2px;
  }
`},uMgb:function(o,t,i){"use strict";i.d(t,"a",(function(){return r}));const r=i("Rukz").c`
  .timeline {
    list-style: none;
    padding: 0px 0px;
    position: relative;
  }

  .timeline:before {
    top: 10px;
    bottom: 0;
    position: absolute;
    content: ' ';
    width: 1px;
    background-color: #989898;
    left: 20px;
    margin-left: -1.5px;
  }

  .timeline .timeline-inverted {
    margin-bottom: 10px;
    position: relative;
  }

  .timeline > .timeline-inverted:before,
  .timeline > .timeline-inverted:after {
    content: ' ';
    display: table;
  }

  .timeline > .timeline-inverted:after {
    clear: both;
  }

  .timeline .timeline-panel {
    width: calc(100% - 70px);
    float: left !important;
    left: 64px;
    border: 1px solid #d4d4d4;
    border-radius: 4px;
    padding: 15px;
    position: relative;
    background-color: #fff;
    font-size: 14px;
    line-height: 23px;
    border-bottom: 5px solid #ddd;
  }

  .timeline .timeline-panel:before {
    position: absolute;
    top: 16px;
    right: -15px;
    display: inline-block;
    border-top: 15px solid transparent;
    border-left: 15px solid #ccc;
    border-right: 0 solid #ccc;
    border-bottom: 15px solid transparent;
    content: ' ';
  }

  .timeline .timeline-panel:after {
    position: absolute;
    top: 17px;
    right: -14px;
    display: inline-block;
    border-top: 14px solid transparent;
    border-left: 14px solid #fff;
    border-right: 0 solid #fff;
    border-bottom: 14px solid transparent;
    content: ' ';
  }

  .timeline .timeline-badge {
    color: #999;
    width: 40px;
    height: 40px;
    line-height: 38px;
    font-size: 1.2em;
    text-align: center;
    position: absolute;
    top: 6px;
    left: 25px;
    margin-left: -25px;
    background-color: #efefef;
    z-index: 100;
    border-top-right-radius: 50%;
    border-top-left-radius: 50%;
    border-bottom-right-radius: 50%;
    border-bottom-left-radius: 50%;
  }

  .timeline > .timeline-inverted > .timeline-panel:before {
    border-left-width: 0;
    border-right-width: 15px;
    left: -15px;
    right: auto;
  }

  .timeline > .timeline-inverted > .timeline-panel:after {
    border-left-width: 0;
    border-right-width: 14px;
    left: -14px;
    right: auto;
  }

  .timeline-heading p {
    margin-top: 0px;
    margin-bottom: 0px !important;
    font-size: 12px !important;
  }

  .timeline-title {
    margin: 0px !important;
    margin-top: 0px;
    color: var(--app-primary-color);
  }

  .timeline-body p {
    margin: 0px !important;
    margin-bottom: 0;
    line-height: 21px;
    font-size: 14px;
  }

  .timeline-body > p + p {
    margin-top: 0px;
  }
`},x4XD:function(o,t,i){"use strict";i.d(t,"a",(function(){return r}));const r=i("Rukz").c`
  .cart {
    background-color: #ffffff;
    border-bottom: 0px solid #ccc;
    list-style: none;
    margin-bottom: 30px;
    border-radius: 4px;
    height: 100px;
    color: var(--app-primary-color);
    -webkit-transition: color 300ms, background-color 300ms, box-shadow 700ms;
    -moz-transition: color 300ms, background-color 300ms, box-shadow 700ms;
    -o-transition: color 300ms, background-color 300ms, box-shadow 700ms;
    transition: color 300ms, background-color 300ms, box-shadow 700ms;
  }
  .cart:hover {
    background-color: #fff;
    box-shadow: 0 5px 20px 0 rgba(62, 57, 107, 0.07), 0 2px 9px 0 rgba(62, 57, 107, 0.06);
    transition: transform 0.4s ease-out, -webkit-transform 0.4s ease-out;
  }
  .cart .icon {
    background-color: #44c455;
    border-right: 6px solid #3ca64a;
    border-bottom: 0px solid #ccc;
    height: 100px;
    width: 100px;
    border-radius: 4px 0px 0px 4px;
    text-align: center;
    color: #fff;
    float: left;
  }
  .cart .icon h3 {
    margin: 0px;
    padding: 0px;
    font-size: 16px;
  }
  .cart .icon iron-icon {
    height: 40px;
    width: 40px;
    margin-top: 14px;
    margin-bottom: 6px;
  }
  .cart.idle .icon {
    background-color: #59a5d8;
    border-right: 6px solid #3994d0;
  }
  .cart.loading .icon {
    background-color: #ffbf2c;
    border-right: 6px solid #f5aa00;
  }
  .cart.busy .icon {
    background-color: #f06061;
    border-right: 6px solid #e64249;
  }
  .cart.abandoned .icon {
    background-color: gray;
    border-right: 6px solid gray;
  }

  .cart .content {
    color: var(--app-primary-color);
    width: calc(100% - 100px);
    margin-left: 100px;
  }
  .cart .content .inner {
    padding: 20px 20px;
  }
  .cart .content.busy .inner {
    padding: 14px 20px;
  }
  .cart .content h3.title {
    margin: 0px;
    padding: 0px;
    font-size: 20px;
  }
  .cart .content p.description {
    margin: 0px;
    padding: 0px;
    font-size: 14px;
    font-weight: bold;
  }
  .cart .content p.description.busy {
    font-weight: bold;
  }
  .cart .content p.subdescription {
    margin: 0px;
    padding: 0px;
    font-size: 12px;
  }

  .progress-wrapper {
    margin-bottom: 5px;
    margin-top: 2px;
  }
`},yeo0:function(o,t,i){"use strict";i("Rukz").c`
  .btn-back {
    color: #999;
    font-weight: 600;
    border-radius: 4px;
    border-width: 0px;
    border-style: solid;
    border-color: rgb(255, 255, 255);
    border-image: initial;
    transition: all 0.1s ease 0s;
    background-color: #fff;
    border: 1px solid #ccc;
    height: 28px !important;
    font-size: 12px;
    text-decoration: none;
    line-height: 28px;
    text-align: center;
    padding: 0px 3px;
  }

  .btn-back:hover {
    background-color: rgb(71, 92, 123);
    color: #fff;
  }
`},ykx1:function(o,t,i){"use strict";i.d(t,"a",(function(){return r}));const r=i("Rukz").c`
  .hide {
    display: none;
  }
  .show {
    display: block;
  }
`},z7EC:function(o,t,i){"use strict";i.d(t,"a",(function(){return r}));const r=i("Rukz").c`
  ul.nostyle {
    padding: 0;
    margin: 0;
    list-style: none;
  }
  ul.nostyle li {
    padding: 3px 10px;
  }
  ul.nostyle li a {
    text-decoration: none;
  }
  .flex-container {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: no-wrap;
  }
  .flex-container.level {
    border-bottom: 1px solid var(--app-primary-color);
    padding: 0.5rem 0;
  }
  .flex-container.item {
    padding: 5px 0;
    font-size: 14px;
  }
  .flex-container.item a {
    text-decoration: none;
    color: var(--app-primary-color);
  }
  .level-back {
    color: var(--app-primary-color);
    font-size: 28px;
  }
  .level-title {
    color: var(--app-primary-color);
    text-transform: uppercase;
    font-weight: bold;
    flex-grow: 8;
  }
  .number-container {
    color: var(--app-primary-color);
    text-align: center;
    flex-basis: 0;
  }
  .icon-container {
    width: 32px;
    text-align: center;
    cursor: pointer;
  }
  .icon-container.margin {
    width: 32px;
    text-align: center;
    cursor: pointer;
    margin-left: 0.55rem;
  }
  .icon-container.basis-0 {
    flex-basis: 0;
  }
  .icon-small {
    font-size: 12px;
  }
  .icon-small-iron {
    font-size: 12px;
    width: 14px;
  }

  .title-container {
    width: 100%;
    flex-grow: 8;
  }
  .title-container:hover {
    color: #111;
  }
  .menu-container {
    width: 100%;
    background: #fafbfc;
    color: #445776;
    height: 100%;
    position: relative;
    padding: 0px;
    border-right: 1px solid rgb(226, 232, 240);
  }

  .menu-container.full-width {
    display: flex;
    flex-direction: row;
    background: transparent;
    color: inherit;
    padding: 0;
  }

  .menu-container.full-width .number-container {
    color: #555;
  }

  .menu-container.full-width .flex-container.item a {
    color: #555;
  }

  .menu-container.full-width .level-title,
  .menu-container.full-width .level-back {
    color: #555;
  }

  .menu-container.full-width .icon-container {
    color: #253655;
  }

  .menu-container.full-width .flex-container.level {
    border-bottom: 1px solid #ccc;
    margin-bottom: 1rem;
  }

  .menu-container.full-width .flex-container.level {
    padding: 1rem 5px;
  }

  .color-star {
    color: #4fc1e9;
    cursor: pointer;
  }

  .color-star:hover {
    color: #45b69c;
    opacity: 0.1;
  }

  .icon-container.low-op {
    color: #eee;
  }

  .low-op {
    color: #45b69c;
    cursor: pointer;
    opacity: 0.3;
  }

  .low-op-b {
    cursor: pointer;
    opacity: 0.4;
  }

  .low-op-b:hover {
    cursor: pointer;
    opacity: 1;
  }

  .low-op:hover {
    opacity: 1;
  }

  .item.flex {
    flex: 1;
  }

  input.item.flex {
    margin-top: 10px;
    padding: 5px;
  }

  .hidden {
    display: none;
  }

  .moveb {
    cursor: pointer;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .bookmarks.full-width {
    flex-grow: 1;
    order: 2;
    padding: 0 1rem;
  }

  .menu.full-width {
    flex-grow: 1;
    padding: 0 1rem;
    order: 1;
  }

  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
`}}]);